import { AgGridReact } from "ag-grid-react";
import { orderBy } from "lodash";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { dateFormatDMY, onGridReady } from "../../utils/utilities";

function ListSubTasks({ subTasks }) {
  const [showFirst, setShowFirst] = useState(false);
  const toggleFirst = () => setShowFirst(!showFirst);

  const [showSecond, setShowSecond] = useState(false);
  const toggleSecond = () => setShowSecond(!showSecond);

  const colSubTasks = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    { field: "name", filter: "agSetColumnFilter" },
    { field: "statusName", width: 65, headerName: "Status" },
    { field: "assigned", headerName: "Staff", width: 65 },
    {
      field: "team",
      headerName: "Team",
      width: 65,
      filter: "agSetColumnFilter",
    },
  ];

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    { field: "name" },
    { field: "statusName", width: 65, headerName: "Status" },
    { field: "estimateHours", width: 65, headerName: "Estimate" },
    { field: "normalHours", width: 65, headerName: "Working" },
    { field: "overTimeHours", width: 65, headerName: "OT" },
    { field: "team", headerName: "Team", width: 65 },
    { field: "assigned", headerName: "Staff", width: 65 },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
  ];

  const listOfTimeSheets = () => {
    return (
      <div className="ag-theme-quartz" style={{ height: 275 }}>
        <AgGridReact
          rowData={orderBy(subTasks ?? [], "priorityNumber")}
          columnDefs={colDefs}
        />
      </div>
    );
  };

  const listOfSubTasks = () => {
    return (
      <div className="ag-theme-quartz" style={{ height: 275 }}>
        <AgGridReact
          rowData={orderBy(subTasks ?? [], "priorityNumber")}
          columnDefs={colSubTasks}
          onGridReady={onGridReady}
        />
      </div>
    );
  };

  const renderItem = () => {
    if (subTasks.length < 1) return <></>;
    return (
      <>
        <div style={{ border: "1px solid gray" }} />
        <MDBRow>
          <MDBCol size="3" sm={9} className=" truncate max-w-[300px]">
            Sub Tasks
          </MDBCol>
          <MDBCol size="3" sm={3}>
            {!showFirst && (
              <MDBIcon
                style={{ cursor: "pointer" }}
                fas
                icon="eye"
                onClick={toggleFirst}
              />
            )}
            {showFirst && (
              <MDBIcon
                style={{ cursor: "pointer" }}
                fas
                icon="eye-slash"
                onClick={toggleFirst}
              />
            )}
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  const renderTimeSheets = () => {
    if (subTasks.length < 1) return <></>;
    return (
      <>
        <div style={{ border: "1px solid gray" }} />
        <MDBRow>
          <MDBCol size="3" sm={9} className=" truncate max-w-[300px]">
            Time Sheets
          </MDBCol>
          <MDBCol size="3" sm={3}>
            {!showSecond && (
              <MDBIcon
                fas
                icon="caret-down"
                color="tertiary"
                onClick={toggleSecond}
              />
            )}
            {showSecond && (
              <MDBIcon
                fas
                icon="caret-up"
                color="tertiary"
                onClick={toggleSecond}
              />
            )}
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  return (
    <>
      {renderItem()}
      {showFirst && listOfSubTasks()}
      {renderTimeSheets()}
      {showSecond && listOfTimeSheets()}
    </>
  );
}

export default ListSubTasks;
