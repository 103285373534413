import ReactApexChart from "react-apexcharts";

function BarChart({ titleChart, headerChart, categories, data}) {

  const options = () => {
    return {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          barHeight: "80%",
          isFunnel: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return (
            opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + " hours"
          );
        },
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: [getRandomColor(), getRandomColor()],
      },
      title: {
        text: titleChart,
        align: "middle",
      },
      xaxis: {
        categories: categories,
      },
      legend: {
        show: false,
      },
    };
  };

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const series = () => {
    return [
      {
        name: headerChart,
        data: data,
      },
    ];
  };

  return (
    <>
      <ReactApexChart
        options={options()}
        series={series()}
        type="bar"
        height={350}
      />
    </>
  );
}

export default BarChart;
