import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import { difference, first } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import UpdateDisplayName from "../Planning/UpdateDisplayName";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onGridReady } from "../../utils/utilities";

function TeamMembers() {
  const navigate = useNavigate();
  const [staffs, setStaffs] = useState();
  const [curStaffs, setCurStaffs] = useState();
  const [employees, setEmployees] = useState();
  const [teams, setTeams] = useState();
  const [teamId, setTeamId] = useState();
  const [teamleader, setTeamleader] = useState("");
  const [userId, setUserId] = useState();
  const [displayName, setDisplayName] = useState();
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Manage teams";
  }, []);

  useEffect(() => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/All-Teams`,
        configHeader()
      )
      .then((result) => {
        setTeams((result?.data ?? []).filter((item) => item.isActive));
      });
  }, []);

  useEffect(() => {
    loadDataStaffs();
  }, []);

  const loadDataStaffs = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs`,
        configHeader()
      )
      .then((result) => {
        let items = filterStaffs(result?.data, []);
        setStaffs(items ?? []);
      });
  };

  const handleEditName = (id, name) => {
    setUserId(id);
    setDisplayName(name);
    setOpenChangeNameModal(true);
  };

  const updateDisplayName = () => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${userId}/UpdateName`,
        { name: displayName },
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        setOpenChangeNameModal(false);
      });
  };

  const handleEditLeader = (id, name) => {
    teamId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Teams/${teamId}/UpdateLeader/${id}`,
          {},
          configHeader()
        )
        .then(() => {
          setTeamleader(name);
          toast.success(
            `Your request change teamleader was successful. Current team leader is ${name}`
          );
        });
  };

  const handleActive = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${id}/Active`,
        {},
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        toast.success(`Your request active ${name} was successful.`);
      });
  };

  const handleBlockUser = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${id}/Disable`,
        {},
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        toast.success(
          `Account of ${name} account has been disabled. The account can't log in to system.`
        );
      });
  };

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "displayName",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        let icon = !row.data.isActive ? (
          ""
        ) : (
          <MDBIcon
            fas
            icon="user"
            title="An individual who regularly engages with and utilizes your platform"
          />
        );
        return (
          <>
            {icon} {row.data.displayName}
          </>
        );
      },
      width: 195,
    },
    {
      field: "team",
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
    },
    {
      field: "actions",
      headerName: "Active user",
      width: 75,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        if (row.data.isActive)
          return (
            <MDBIcon
              fas
              icon="user-alt-slash"
              title={`Disable your account ${row.data.displayName}`}
              color="danger"
              onClick={() => handleBlockUser(row.data.id, row.data.displayName)}
            />
          );

        return (
          <MDBIcon
            fas
            icon="user-check"
            title={`Active account of ${row.data.displayName}`}
            color="info"
            onClick={() => handleActive(row.data.id, row.data.displayName)}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Update Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 75,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="user-edit"
            title="Change display name"
            onClick={() => handleEditName(row.data.id, row.data.displayName)}
          />
        );
      },
    },
  ];

  const columDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 45,
    },
    {
      field: "displayName",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        let icon =
          teamleader !== row.data.displayName ? (
            ""
          ) : (
            <MDBIcon fas icon="user-circle" title="This is a team leader" />
          );
        return (
          <>
            {icon} {row.data.displayName}
          </>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Active/Disabled",
      cellStyle: {
        textAlign: "left",
      },
      width: 95,
    },
    {
      field: "team",
      cellStyle: {
        textAlign: "left",
      },
      width: 95,
    },
    {
      field: "actions",
      headerName: "Teamleader",
      cellStyle: {
        textAlign: "left",
      },
      width: 75,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="user-circle"
            title="Change team leader"
            style={{ cursor: "pointer" }}
            onClick={() => handleEditLeader(row.data.id, row.data.displayName)}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 75,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="trash-alt"
            color="warning"
            style={{ cursor: "pointer" }}
            onClick={() => handleRemove(row.data.id)}
          />
        );
      },
    },
  ];

  const handleRemove = (id) => {
    let rs = (employees ?? []).filter((s) => s.id !== id);
    let items = filterStaffs(staffs, rs);
    setStaffs(items);
    setEmployees(rs);
  };

  const onSelectionChanged = (event) => {
    const selectedData = event.api.getSelectedRows();
    setCurStaffs(selectedData);
  };

  const handleSelect = () => {
    let rs = (employees ?? []).concat(curStaffs ?? []);
    let items = filterStaffs(staffs, rs);
    setStaffs(items);
    setEmployees(rs);
  };

  const filterStaffs = (items, others) => {
    let rs = (items ?? []).filter((item) => item.pricing > 0);
    return difference(rs, others);
  };

  const options = () => {
    return (teams ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (teams ?? []).filter((p) => ids.some((id) => id === p.id));
    let team = first(rs) ?? {};
    setTeamleader(team?.teamleader);
    setTeamId(team?.id);
  };

  useEffect(() => {
    reloadTeamInfo(teamId);
  }, [teamId]);

  const reloadTeamInfo = (teamId) => {
    teamId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Teams/${teamId}/Staffs`,
          configHeader()
        )
        .then((result) => {
          let items = result?.data ?? [];
          loadDataInitByTeam(items);
        });
  };

  const loadDataInitByTeam = (listStaffs) => {
    let ids = (listStaffs ?? []).map((it) => it.id);
    let rs = (staffs ?? []).filter((p) => ids.some((id) => id === p.id));
    let items = filterStaffs(staffs, rs);
    setStaffs(items);
    setEmployees(rs);
  };

  const saveData = () => {
    let staffIds = (employees ?? []).map((item) => item.id);
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Teams/${teamId}/Staffs`,
        {
          staffIds: staffIds,
        },
        configHeader()
      )
      .then(() => {
        setTeamId(0);
        setTeamleader("");
        setEmployees([]);
        loadDataStaffs();
      });
  };

  return (
    <>
      {openChangeNameModal && (
        <UpdateDisplayName
          setIsOpenModal={setOpenChangeNameModal}
          displayName={displayName}
          setDisplayName={setDisplayName}
          updateDisplayName={updateDisplayName}
        />
      )}
      <MDBRow style={{ marginTop: 10 }}>
        <MDBCol size="12" sm="6">
          <div className="ag-theme-quartz" style={{ height: "65vh" }}>
            <AgGridReact
              rowData={staffs}
              columnDefs={colDefs}
              rowSelection={"multiple"}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
            />
          </div>
          Selecting multiple rows can be achieved by holding down ^ Ctrl and
          mouse clicking the rows. A range of rows can be selected by using ⇧
          Shift.
        </MDBCol>
        <MDBCol size="12" sm="1">
          <MDBBtn
            type="submit"
            block
            className="mb-4"
            style={{ cursor: "pointer", marginTop: 75 }}
            onClick={() => handleSelect()}
          >
            <MDBIcon fas icon="chevron-circle-right" />
          </MDBBtn>
        </MDBCol>

        <MDBCol size="6" sm="5">
          <MDBRow>
            <MDBCol size="6" sm="12">
              <Multiselect
                options={options()}
                singleSelect
                placeholder="Select a team"
                onSelect={(items) => onSelectedValues(items)}
                onRemove={(items) => onSelectedValues(items)}
                displayValue="name"
              />
            </MDBCol>
          </MDBRow>

          <div>Team leader : {teamleader}</div>
          <MDBRow>
            <MDBCol size="6" sm="12">
              <div
                className="ag-theme-quartz"
                style={{ height: "50vh", marginTop: 20 }}
              >
                {employees && (
                  <AgGridReact rowData={employees} columnDefs={columDefs} onGridReady={onGridReady} />
                )}
              </div>
            </MDBCol>
          </MDBRow>

          <MDBBtn
            type="submit"
            block
            className="btn btn-outline-primary"
            style={{ cursor: "pointer", marginTop: 15 }}
            disabled={(teamId ?? 0) < 1}
            onClick={() => saveData()}
          >
            <MDBIcon fas icon="save" /> Save resources to team
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <ToastContainer />
    </>
  );
}

export default TeamMembers;
