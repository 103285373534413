import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSwitch,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import AssignTaskToTeam from "./AssignTaskToTeam";
import { first } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateTaskInfo({
  teams,
  taskDetailInfo,
  userRole,
  onCloseModal,
  onSave,
}) {
  const navigate = useNavigate();
  const [taskName, setTaskName] = useState(taskDetailInfo?.name);
  const [startDate, setStartDate] = useState(
    taskDetailInfo?.startDate ?? new Date()
  );
  const [endDate, setEndDate] = useState(taskDetailInfo?.endDate ?? new Date());
  const [priorityNumber, setPriorityNumber] = useState(
    taskDetailInfo?.priorityNumber
  );
  const [description, setDescription] = useState(taskDetailInfo?.description);
  const [estimateHours, setEstimateHours] = useState(
    taskDetailInfo?.estimateHours
  );
  const [currentTeams, setCurrentTeams] = useState(
    taskDetailInfo?.teamIds ?? []
  );
  const [showInMenu, setShowInMenu] = useState(false);
  const [userTeamId, setUserTeamId] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);

    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserTeamId(infoObj.teamId);
  }, []);

  useEffect(() => {
    setTaskName(taskDetailInfo?.name);
    setStartDate(taskDetailInfo?.startDate ?? new Date());
    setEndDate(taskDetailInfo?.endDate ?? new Date());
    setPriorityNumber(taskDetailInfo?.priorityNumber);
    setDescription(taskDetailInfo?.description);
    setEstimateHours(taskDetailInfo?.estimateHours);
    setShowInMenu(taskDetailInfo?.showInMenu);
    setCurrentTeams(taskDetailInfo?.teamIds ?? []);
  }, [taskDetailInfo]);

  const handleShowInMenu = (checked) => {
    setShowInMenu(checked);
  };

  const saveData = () => {
    if (taskDetailInfo?.id < 1) {
      toast.error("Please select task again.");
    } else {
      let teamIds = currentTeams;

      if (userRole !== "Admin" && userRole !== "Manager") {
        teamIds = (teams ?? [])
          .filter((item) => +userTeamId === +item.id)
          .map((x) => x.id);
      }

      onSave({
        id: taskDetailInfo?.id,
        name: taskName,
        estimateHours: estimateHours,
        startDate: startDate,
        endDate: endDate,
        priorityNumber: priorityNumber,
        description: description,
        showInMenu: showInMenu,
        teamIds: teamIds,
      });
      onCloseModal();
    }
  };

  const handleUseTeam = (teamId, checked) => {
    let current = currentTeams ?? [];
    if (checked) {
      let hasVal = (currentTeams ?? []).filter((it) => it === teamId);
      if ((hasVal ?? []).length < 1) {
        current.push(teamId);
      }
    } else {
      current = (currentTeams ?? []).filter((it) => it !== teamId);
    }
    setCurrentTeams(current ?? []);
  };

  const renderTeamByUserRole = () => {
    if (userRole !== "Admin" && userRole !== "Manager") {
      let current = (teams ?? []).filter((item) => +userTeamId === +item.id);
      let info = first(current);
      return (
        <MDBRow title={`My team ${info?.name}`}>
          <MDBCol size="6" sm="10" color="secondary">
            <MDBBtn color="secondary">My team {info?.name}</MDBBtn>
          </MDBCol>
        </MDBRow>
      );
    }

    return (
      <>
        {(teams ?? []).map((item) => {
          let isChecked =
            (taskDetailInfo?.teamIds ?? []).filter((it) => it === item.id)
              ?.length > 0;

          return (
            <AssignTaskToTeam
              info={item}
              canModify={true}
              isChecked={isChecked}
              handleUseTeam={(teamId, checked) =>
                handleUseTeam(teamId, checked)
              }
            />
          );
        })}
      </>
    );
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          title="Name of task"
          value={taskName}
          onChange={(evt) => setTaskName(evt.target.value)}
        />
        <MDBAccordion alwaysOpen initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle="Task information">
            <MDBRow>
              <MDBCol sm="12" style={{ textAlign: "left" }}>
                <label className="  text-sm dark:text-white text-gray-500">
                  Plan completed date
                </label>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="3" sm="6">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </MDBCol>
              <MDBCol size="3" sm="6">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ textAlign: "left", marginTop: 20 }}>
              <MDBCol size="3" sm="3">
                <MDBInput
                  className="mb-3"
                  type="number"
                  label="Estimate"
                  title="Estimate Hours"
                  value={estimateHours}
                  style={{ padding: 5 }}
                  onChange={(evt) => setEstimateHours(evt.target.value)}
                />
              </MDBCol>
              <MDBCol size="6" sm="3">
                <MDBInput
                  className="mb-3"
                  type="number"
                  label="Priority"
                  value={priorityNumber}
                  style={{ padding: 5 }}
                  onChange={(evt) => setPriorityNumber(evt.target.value)}
                />
              </MDBCol>
              <MDBCol size="6" sm="6">
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  label={"Show In Menu"}
                  style={{ marginTop: 5 }}
                  onChange={(e) => handleShowInMenu(e.target.checked)}
                ></MDBSwitch>
              </MDBCol>
            </MDBRow>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle="Team information">
            {renderTeamByUserRole()}
          </MDBAccordionItem>
        </MDBAccordion>
        <MDBTextArea
          className="mb-3"
          label="Description"
          id="textAreaDescription"
          rows="{4}"
          style={{ marginTop: 20 }}
          value={description}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  return (
    <>
      <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Update Task {taskDetailInfo?.name}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={onCloseModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="divScroll50Vh">
                {renderBody()}
              </div>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={onCloseModal}>
                Close
              </MDBBtn>
              <MDBBtn onClick={() => saveData()}>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer />
    </>

  );
}

export default UpdateTaskInfo;
