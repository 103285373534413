import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSwitch,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

function ClonePhase({
  isCloneOpen,
  userRole,
  phaseInfo,
  statuses,
  onSave,
  setIsCloneOpen,
}) {
  const [phaseName, setPhaseName] = useState(`Clone ${phaseInfo?.name}`);
  const [priorityNumber, setPriorityNumber] = useState(
    phaseInfo?.priorityNumber
  );
  const [description, setDescription] = useState(phaseInfo?.description);
  const [startDate, setStartDate] = useState(
    phaseInfo?.startDate ?? new Date()
  );
  const [endDate, setEndDate] = useState(phaseInfo?.endDate ?? new Date());
  const [statusName, setStatusName] = useState();

  useEffect(() => {
    setPhaseName(`Clone ${phaseInfo?.name}`);
    setPriorityNumber(phaseInfo?.priorityNumber);
    setDescription(phaseInfo?.description);
    setStatusName(phaseInfo?.statusName);
    setStartDate(phaseInfo?.startDate ?? new Date());
    setEndDate(phaseInfo?.endDate ?? new Date());
  }, [phaseInfo]);

  const handleCurrent = (name, checked) => {
    if (checked) {
      setStatusName(name);
    }
  };

  const renderHeaderPage = () => {
    return <MDBModalTitle>Clone {phaseInfo?.name}</MDBModalTitle>;
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          defaultValue={phaseName}
          onChange={(evt) => setPhaseName(evt.target.value)}
        />
        <MDBRow>
          <MDBCol sm="12" style={{ textAlign: "left" }}>
            <MDBCol
              sm="12"
              style={{
                marginBottom: 5,
                textAlign: "left",
              }}
            >
              <label className="  text-sm dark:text-white text-gray-500">
                Plan completed date
              </label>
            </MDBCol>
          </MDBCol>
          <MDBCol size="6" sm="6">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </MDBCol>
          <MDBCol size="6" sm="6">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="6" sm="9">
            Current Status
          </MDBCol>
        </MDBRow>
        {(statuses ?? []).map((item) => {
          return (
            <MDBRow style={{ marginBottom: 10 }}>
              <MDBCol size="3" sm="2">
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  checked={statusName === item?.statusName}
                  onChange={(e) =>
                    handleCurrent(item.statusName, e.target.checked)
                  }
                />
              </MDBCol>
              <MDBCol size="3" sm="8">
                {item?.statusName}
              </MDBCol>
            </MDBRow>
          );
        })}
        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol size="3" sm="3">
            <MDBInput
              className="mb-3"
              type="number"
              label="Priority"
              defaultValue={priorityNumber}
              style={{ padding: 5 }}
              onChange={(evt) => setPriorityNumber(evt.target.value)}
            />
          </MDBCol>
          <MDBCol size="3" sm="9">
            <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
            {phaseInfo?.assigned}
          </MDBCol>
        </MDBRow>
        <MDBTextArea
          className="mb-3"
          label="Description"
          id="textAreaExample"
          rows="{4}"
          defaultValue={description}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  const renderControlByRole = () => {
    if (userRole !== "Admin" && userRole !== "Manager") return <></>;
    return <MDBBtn onClick={() => saveData()}>Clone Phase</MDBBtn>;
  };

  const saveData = () => {
    onSave(phaseInfo?.id, {
      name: phaseName,
      startDate: startDate,
      endDate: endDate,
      priorityNumber: priorityNumber,
      description: description,
    });
    setIsCloneOpen(false);
  };

  return (
    <MDBModal
      open={isCloneOpen}
      onClose={() => setIsCloneOpen(false)}
      tabIndex="-1"
    >
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            {renderHeaderPage()}
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setIsCloneOpen(false)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>{renderBody()}</MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setIsCloneOpen(false)}>
              Close
            </MDBBtn>
            {renderControlByRole()}
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default ClonePhase;
