import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import { difference, first, orderBy } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import UpdateDisplayName from "./UpdateDisplayName";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onGridReady } from "../../utils/utilities";

function ResourcePlan() {
  const navigate = useNavigate();
  const [staffs, setStaffs] = useState();
  const [curStaffs, setCurStaffs] = useState();
  const [employees, setEmployees] = useState();
  const [projects, setProjects] = useState();
  const [phases, setPhases] = useState();
  const [phaseId, setPhaseId] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [userId, setUserId] = useState();
  const [displayName, setDisplayName] = useState();
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Manage resources";
  }, []);

  useEffect(() => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = result?.data ?? [];
        setProjects(orderBy(items, "priorityNumber"));
      });
  }, []);

  useEffect(() => {
    loadDataStaffs();
  }, []);

  const handleEditName = (id, name) => {
    setUserId(id);
    setDisplayName(name);
    setOpenChangeNameModal(true);
  };

  const updateDisplayName = () => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${userId}/UpdateName`,
        { name: displayName },
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        if ((phaseId ?? 0) > 0) reloadPhaseInfo(phaseId);
        setOpenChangeNameModal(false);
      });
  };

  const loadDataStaffs = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs`,
        configHeader()
      )
      .then((result) => {
        let items = filterStaffs(result?.data, []);
        setStaffs(items);
      });
  };

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "displayName",
      width: 195,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "team",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actions",
      headerName: "Update Name",
      width: 75,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="user-edit"
            onClick={() => handleEditName(row.data.id, row.data.displayName)}
          />
        );
      },
    },
  ];

  const columDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "displayName",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "team",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 75,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="trash-alt"
            color="warning"
            onClick={() => handleRemove(row.data.id)}
          />
        );
      },
    },
  ];

  const handleRemove = (id) => {
    let rs = (employees ?? []).filter((s) => s.id !== id);
    let items = filterStaffs(staffs, rs);
    setStaffs(items);
    setEmployees(rs);
  };

  const onSelectionChanged = (event) => {
    const selectedData = event.api.getSelectedRows();
    setCurStaffs(selectedData);
  };

  const handleSelect = () => {
    let rs = (employees ?? []).concat(curStaffs ?? []);
    let items = filterStaffs(staffs, rs);
    setStaffs(items);
    setEmployees(rs);
  };

  const filterStaffs = (items, others) => {
    let rs = (items ?? []).filter((item) => item.pricing > 0);
    return difference(rs, others);
  };

  const options = () => {
    return (projects ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const optionPhases = () => {
    return (phases ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (projects ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setPhases(pro?.phaseInfo);
  };

  const onSelectedPhaseValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (phases ?? []).filter((p) => ids.some((id) => id === p.id));
    let phase = first(rs) ?? {};
    setPhaseId(phase?.id);
  };

  useEffect(() => {
    reloadPhaseInfo(phaseId);
  }, [phaseId]);

  const reloadPhaseInfo = (phaseId) => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/${phaseId}`,
          configHeader()
        )
        .then((result) => {
          let items = result?.data?.staffs ?? [];
          loadDataInitByPhase(items);
        });
  };

  const loadDataInitByPhase = (listStaffs) => {
    let ids = (listStaffs ?? []).map((it) => it.id);
    let rs = (staffs ?? []).filter((p) => ids.some((id) => id === p.id));
    let items = filterStaffs(staffs, rs);
    setStaffs(items);
    setEmployees(rs);
  };

  const saveData = () => {
    let staffIds = (employees ?? []).map((item) => item.id);
    phaseId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/${phaseId}/Resources`,
          {
            staffIds: staffIds,
            startDate: startDate,
            endDate: endDate,
          },
          configHeader()
        )
        .then(() => {
          reloadPhaseInfo(phaseId);
          toast.success("Assign resources to project was successful");
        });
  };

  return (
    <>
      {openChangeNameModal && (
        <UpdateDisplayName
          setIsOpenModal={setOpenChangeNameModal}
          displayName={displayName}
          setDisplayName={setDisplayName}
          updateDisplayName={updateDisplayName}
        />
      )}
      <MDBRow>
        <MDBCol size="12" sm="6">
          <Multiselect
            options={options()}
            singleSelect
            onSelect={(items) => onSelectedValues(items)}
            onRemove={(items) => onSelectedValues(items)}
            displayValue="name"
          />
        </MDBCol>
        <MDBCol size="12" sm="6">
          <Multiselect
            options={optionPhases()}
            singleSelect
            onSelect={(items) => onSelectedPhaseValues(items)}
            onRemove={(items) => onSelectedPhaseValues(items)}
            displayValue="name"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 10 }}>
        <MDBCol size="12" sm="6">
          <div className="ag-theme-quartz" style={{ height: "65vh" }}>
            <AgGridReact
              rowData={staffs}
              columnDefs={colDefs}
              rowSelection={"multiple"}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
            />
          </div>
          Selecting multiple rows can be achieved by holding down ^ Ctrl and
          mouse clicking the rows. A range of rows can be selected by using ⇧
          Shift.
        </MDBCol>
        <MDBCol size="12" sm="1">
          <MDBBtn
            type="submit"
            block
            className="mb-4"
            style={{ marginTop: 75 }}
            onClick={() => handleSelect()}
          >
            <MDBIcon fas icon="chevron-circle-right" />
          </MDBBtn>
        </MDBCol>

        <MDBCol size="12" sm="5">
          <div className="ag-theme-quartz" style={{ height: "60vh" }}>
            {employees && (
              <AgGridReact rowData={employees} columnDefs={columDefs} />
            )}
          </div>

          <MDBRow>
            <MDBCol sm="12" style={{ textAlign: "left" }}>
              <MDBCol
                sm="12"
                style={{
                  margin: 10,
                  marginBottom: 5,
                  textAlign: "left",
                }}
              >
                <label className="  text-sm dark:text-white text-gray-500">
                  Plan date
                </label>
              </MDBCol>
            </MDBCol>
            <MDBCol size="6" sm="6">
              <DatePicker
                selected={startDate}
                className="css-from-date-project"
                onChange={(date) => setStartDate(date)}
              />
            </MDBCol>
            <MDBCol size="6" sm="6">
              <DatePicker
                selected={endDate}
                className="css-from-date-project"
                onChange={(date) => setEndDate(date)}
              />
            </MDBCol>
          </MDBRow>
          <MDBBtn
            type="submit"
            block
            className="btn btn-outline-primary"
            style={{ marginTop: 15 }}
            disabled={(phaseId ?? 0) < 1}
            onClick={() => saveData()}
          >
            <MDBIcon fas icon="save" /> Save resources to phase
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <ToastContainer />
    </>
  );
}

export default ResourcePlan;
