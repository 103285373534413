import { first } from "lodash";
import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";

const roleNames = [
  { id: "Admin", displayName: "Admin" },
  { id: "User", displayName: "User" },
  { id: "Teamleader", displayName: "Teamleader" },
  { id: "Manager", displayName: "Project Manager" },
  { id: "Accountant", displayName: "Accountant" },
];

function UpdateRoleName({ setIsOpenModal, displayName, roleName, saveData }) {
  const [userRole, setUserRole] = useState();

  const roleOptions = () => {
    return (roleNames ?? []).map((p) => {
      return { name: p.displayName, id: p.id };
    });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (roleNames ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setUserRole(pro?.id);
  };

  return (
    <MDBModal open={true} onClose={() => setIsOpenModal(false)} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Update Role Name</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setIsOpenModal(false)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol size="3" sm="6" className=" truncate max-w-[150px]">
                {displayName}
              </MDBCol>
              <MDBCol size="3" sm="6" className=" truncate max-w-[150px]">
                {roleName}
              </MDBCol>
            </MDBRow>
            <div style={{ border: "1px solid gray", marginBottom: 20 }} />
            <Multiselect
              options={roleOptions()}
              onSelect={(items) => onSelectedValues(items)}
              onRemove={(items) => onSelectedValues(items)}
              displayValue="name"
              placeholder="Select a role for view"
              singleSelect
            />
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setIsOpenModal(false)}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData(userRole)}>Save </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default UpdateRoleName;
