import { MDBCol, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import { useState } from "react";

function CtrStatusInfo({
  statuses,
  curStatusFinish,
  curStatusName,
  curStatusNames,
  onSaveStatusName,
  onSaveStatusIdFinish,
  onSaveStatusIds,
}) {
  const [statusIds, setStatusIds] = useState([]);
  const [statusFinish, setStatusFinish] = useState(curStatusFinish);
  const [statusName, setStatusName] = useState(curStatusName ?? "");

  const handleUse = (id, checked) => {
    if (checked) {
      let ids = statusIds;
      let hasVal = (statusIds ?? []).filter((it) => it === id);
      if ((hasVal ?? []).length < 1) {
        ids.push(id);
      }
      setStatusIds(ids);
      onSaveStatusIds(ids);
    } else {
      let ids = statusIds.filter((it) => it !== id);
      setStatusIds(ids);
      onSaveStatusIds(ids);
    }
  };

  const handleFinished = (id, checked) => {
    if (checked) {
      setStatusFinish(id);
      onSaveStatusIdFinish(id);
    }
  };

  const handleCurrent = (name, checked) => {
    if (checked) {
      setStatusName(name);
      onSaveStatusName(name);
    }
  };

  return (
    <>
      {(statuses ?? []).map((item) => {
        let isUsed = (curStatusNames ?? []).filter(
          (name) => name === item?.statusName
        );

        return (
          <MDBRow style={{ marginBottom: 10 }}>
            <MDBCol size="6" sm="1">
              <MDBSwitch
                id="flexSwitchCheckDefault"
                checked={isUsed}
                onChange={(e) => handleUse(item.id, e.target.checked)}
              />
            </MDBCol>
            <MDBCol size="6" sm="5" style={{ textAlign: "left" }}>
              {item?.statusName}
            </MDBCol>
            <MDBCol size="6" sm="3">
              <MDBSwitch
                id="flexSwitchCheckDefault"
                label="Current"
                checked={statusName === item?.statusName}
                onChange={(e) =>
                  handleCurrent(item.statusName, e.target.checked)
                }
              />
            </MDBCol>
            <MDBCol size="6" sm="3">
              <MDBSwitch
                id="flexSwitchCheckDefault"
                label="Finished"
                checked={statusFinish === item?.id}
                onChange={(e) => handleFinished(item.id, e.target.checked)}
              />
            </MDBCol>
          </MDBRow>
        );
      })}
    </>
  );
}

export default CtrStatusInfo;
