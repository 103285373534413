import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";

import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

function UserInfo() {
  const [name, setName] = useState();
  const [userName, setUserName] = useState();
  const [displayName, setDisplayName] = useState();
  const [userRole, setUserRole] = useState();
  const [myTeam, setMyTeam] = useState();
  const [myTeamleader, setMyTeamleader] = useState();
  const [changePass, setChangePass] = useState(false);
  const [showMyPass, setShowMyPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const toggleOpen = () => setChangePass(!changePass);

  const [myPassword, setMyPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Multiple projects";

    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
  }, []);

  useEffect(() => {
    reloadUserInfo(id);
  }, [id]);

  const reloadUserInfo = (userId) => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/${userId}`,
        configHeader()
      )
      .then((result) => {
        var userInfo = result?.data;
        setUserName(userInfo?.userName);
        setName(userInfo?.displayName);
        setDisplayName(userInfo?.displayName);
        setMyTeam(userInfo?.team);
        setMyTeamleader(userInfo?.teamleader);
      });
  };

  const updateDisplayName = () => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${id}/UpdateName`,
        { name: displayName },
        configHeader()
      )
      .then(() => {
        reloadUserInfo(id);
        toast.success("Your name was changed successful.");
      });
  };

  const updatePassWord = () => {
    if (newPassword !== confirmPassword) {
      toast.error("Confirm Password incorrect, please try again.");
      return;
    } else {
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${id}/Change-password`,
          {
            oldPassword: myPassword,
            newPassword: newPassword,
          },
          configHeader()
        )
        .then(() => {
          handleLogOut();
        });
    }
  };

  const handleLogOut = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("userInfo");
    navigate(`/`);
  };

  const renderChangePass = () => {
    return (
      <MDBModal
        open={changePass}
        onClose={() => setChangePass(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className=" truncate max-w-[350px]">
                Change password of {name}
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow style={{ textAlign: "left", marginTop: 25 }}>
                <MDBCol sm="12">
                  <MDBInput label="My user name" readonly disabled={true}>
                    {userName}
                  </MDBInput>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ textAlign: "left", marginTop: 25 }}>
                <MDBCol size="3" sm="9">
                  <MDBInput
                    type={showMyPass ? "text" : "password"}
                    className="mb-3"
                    label="Current password"
                    title="Change my password"
                    onChange={(evt) => setMyPassword(evt.target.value)}
                  />
                </MDBCol>
                <MDBCol size="3" sm="3">
                  {showMyPass && (
                    <MDBIcon
                      fas
                      icon="eye-slash"
                      onClick={() => setShowMyPass(false)}
                    />
                  )}
                  {!showMyPass && (
                    <MDBIcon
                      fas
                      icon="eye"
                      onClick={() => setShowMyPass(true)}
                    />
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ textAlign: "left", marginTop: 15 }}>
                <MDBCol size="3" sm="9">
                  <MDBInput
                    className="mb-3"
                    type={showNewPass ? "text" : "password"}
                    label="New password"
                    title="New password"
                    onChange={(evt) => setNewPassword(evt.target.value)}
                  />
                </MDBCol>
                <MDBCol size="3" sm="3">
                  {showNewPass && (
                    <MDBIcon
                      fas
                      icon="eye-slash"
                      onClick={() => setShowNewPass(false)}
                    />
                  )}
                  {!showNewPass && (
                    <MDBIcon
                      fas
                      icon="eye"
                      onClick={() => setShowNewPass(true)}
                    />
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ textAlign: "left", marginTop: 15 }}>
                <MDBCol size="3" sm="9">
                  <MDBInput
                    className="mb-3"
                    type={showConfirmPass ? "text" : "password"}
                    label="Confirm password"
                    title="Confirm password"
                    onChange={(evt) => setConfirmPassword(evt.target.value)}
                  />
                </MDBCol>
                <MDBCol sm="3">
                  {showConfirmPass && (
                    <MDBIcon
                      fas
                      icon="eye-slash"
                      onClick={() => setShowConfirmPass(false)}
                    />
                  )}
                  {!showConfirmPass && (
                    <MDBIcon
                      fas
                      icon="eye"
                      onClick={() => setShowConfirmPass(true)}
                    />
                  )}
                </MDBCol>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen}>
                Close
              </MDBBtn>
              <MDBBtn onClick={() => updatePassWord()}>
                <MDBIcon fas icon="key" /> Save
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  };

  return (
    <>
      {changePass && renderChangePass()}
      <label className="  text-sm dark:text-white text-gray-500 truncate max-w-[500px] md:text-2xl text-xl font-bold md:ml-20 font-sans  ">
        My Personal Information
      </label>
      <MDBRow style={{ textAlign: "left", marginTop: 25 }}>
        <MDBCol sm="6" className=" truncate max-w-[400px]">
          <MDBInput label="My display name" readonly disabled>
            {name}
          </MDBInput>
        </MDBCol>
        <MDBCol sm="6" className=" truncate max-w-[400px]">
          <MDBInput label="My role" readonly disabled>
            {userRole}
          </MDBInput>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ textAlign: "left", marginTop: 25 }}>
        <MDBCol sm="6" className=" truncate max-w-[350px]">
          <MDBInput label="My Team" readonly disabled>
            {myTeam}
          </MDBInput>
        </MDBCol>
        <MDBCol sm="6" className=" truncate max-w-[350px]">
          <MDBInput label="Teamleader" readonly disabled>
            {myTeamleader}
          </MDBInput>
        </MDBCol>
      </MDBRow>
      <div style={{ border: "1px solid gray", marginTop: 25 }} />
      <MDBRow style={{ textAlign: "left", marginTop: 25 }}>
        <MDBCol sm="9" className=" truncate max-w-[450px]">
          <MDBInput
            className="mb-3"
            type="text"
            label="Display name"
            title="Change my display name"
            onChange={(evt) => setDisplayName(evt.target.value)}
          />
        </MDBCol>
        <MDBCol sm="3">
          <MDBBtn onClick={() => updateDisplayName()}>Save </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ textAlign: "left", marginTop: 25 }}>
        <MDBCol sm="9" className=" truncate max-w-[450px]">
          <div onClick={toggleOpen}>
            <MDBIcon fas icon="key" /> Change password
          </div>
        </MDBCol>
      </MDBRow>
      <ToastContainer />
    </>
  );
}

export default UserInfo;
