import { jwtDecode } from "jwt-decode";
import { isNullOrUndefine } from "./utilities";

export const configHeader = () => {
  const accessToken = window.localStorage.getItem("accessToken") ?? "";
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const isTokenExpired = () => {
  try {
    const accessToken = window.localStorage.getItem("accessToken") ?? "";
    if (isNullOrUndefine(accessToken)) {
      window.localStorage.removeItem("accessToken");
      return true;
    }

    let decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    let isExpired = (decodedToken?.exp ?? 0) < currentTime;
    if (isExpired) {
      window.localStorage.removeItem("accessToken");
    }

    return isExpired;
  } catch {
    window.localStorage.setItem("accessToken", "");
    return true;
  }
};
