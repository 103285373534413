import React from "react";
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import "./Project.css";
import CheckListItem from "./CheckListItem";

function PhaseCheckList({
  open,
  teamId,
  phaseInfo,
  checkListOfPhase,
  handleOnClose,
  saveData,
}) {
  const handleChangeParagraph = (item, targetChecked) => {
    let data = {
      teamId: teamId,
      parentParagraphId: 0,
      paragraphId: item?.id,
      paragraphItemId: 0,
      paragraphSubItemId: 0,
    };
    saveData(phaseInfo?.id, data, targetChecked);
  };

  const handleChangeSubParagraph = (item, targetChecked) => {
    let data = {
      teamId: teamId,
      parentParagraphId: 0,
      paragraphId: 0,
      paragraphItemId: item?.id,
      paragraphSubItemId: 0,
    };
    saveData(phaseInfo?.id, data, targetChecked);
  };

  const renderBody = () => {
    return (
      <>
        {(checkListOfPhase ?? []).map((item) => {
          return (
            <MDBAccordion initialActive={1}>
              <MDBAccordionItem collapseId={item?.id} headerTitle={item?.name}>
                <MDBRow>
                  <MDBCol sm="10" style={{ textAlign: "left", margin: 15 }}>
                    {item?.name}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="3" sm="1"></MDBCol>
                  <MDBCol size="3" sm="10">
                    {(item?.paragraphs).map((p) => {
                      return (
                        <>
                          <MDBRow>
                            <MDBCol size="3" sm="10">
                              <MDBRow>
                                <CheckListItem
                                  isChecked={p?.isChecked}
                                  displayName={p?.name}
                                  handleChange={(targetChecked) =>
                                    handleChangeParagraph(p, targetChecked)
                                  }
                                />
                              </MDBRow>
                              <MDBRow>
                                <MDBCol size="3" sm="1"></MDBCol>
                                <MDBCol size="3" sm="10">
                                  {(p?.headings).map((h) => {
                                    return (
                                      <CheckListItem
                                        isChecked={h?.isChecked}
                                        displayName={h?.name}
                                        handleChange={(targetChecked) =>
                                          handleChangeSubParagraph(
                                            h,
                                            targetChecked
                                          )
                                        }
                                      />
                                    );
                                  })}
                                </MDBCol>
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                        </>
                      );
                    })}
                  </MDBCol>
                </MDBRow>
              </MDBAccordionItem>
            </MDBAccordion>
          );
        })}
      </>
    );
  };
  return (
    <>
      <MDBModal
        open={open}
        onClose={handleOnClose}
        tabIndex="-1"
        className="checkListDialog"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{phaseInfo?.name}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                style={{ cursor: "pointer" }}
                onClick={handleOnClose}
              ></MDBBtn>
            </MDBModalHeader>

            <MDBModalBody>
              <div className="divScroll">{renderBody()}</div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                style={{ cursor: "pointer" }}
                onClick={handleOnClose}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default PhaseCheckList;
