import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSwitch,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import AssignTaskToTeam from "./AssignTaskToTeam";
import { first } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateNewTask({
  phaseId,
  phaseName,
  statuses,
  teams,
  userRole,
  onCloseModal,
  onSave,
}) {
  const navigate = useNavigate();
  const [taskName, setTaskName] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [priorityNumber, setPriorityNumber] = useState();
  const [description, setDescription] = useState();
  const [statusName, setStatusName] = useState();
  const [estimateHours, setEstimateHours] = useState();
  const [currentTeams, setCurrentTeams] = useState([]);
  const [showInMenu, setShowInMenu] = useState(false);
  const [userTeamId, setUserTeamId] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);

    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserTeamId(infoObj.teamId);
  }, []);

  const handleCurrent = (name, checked) => {
    if (checked) {
      setStatusName(name);
    }
  };

  const handleShowInMenu = (checked) => {
    setShowInMenu(checked);
  };

  const saveData = () => {
    if (phaseId < 1) {
      toast.error("Please select phase again.");
    } else {
      let teamIds = currentTeams;

      if (userRole !== "Admin" && userRole !== "Manager") {
        teamIds = (teams ?? [])
          .filter((item) => +userTeamId === +item.id)
          .map((x) => x.id);
      }

      onSave({
        name: taskName,
        estimateHours: estimateHours,
        startDate: startDate,
        endDate: endDate,
        priorityNumber: priorityNumber,
        phaseId: phaseId,
        statusName: statusName,
        description: description,
        showInMenu: showInMenu,
        teamIds: teamIds,
      });
      onCloseModal();
    }
  };

  const handleUseTeam = (teamId, checked) => {
    let current = currentTeams ?? [];
    if (checked) {
      let hasVal = (currentTeams ?? []).filter((it) => it === teamId);
      if ((hasVal ?? []).length < 1) {
        current.push(teamId);
      }
    } else {
      current = (currentTeams ?? []).filter((it) => it !== teamId);
    }

    setCurrentTeams(current ?? []);
  };

  const renderTeamByUserRole = () => {
    if (userRole !== "Admin" && userRole !== "Manager") {
      let current = (teams ?? []).filter((item) => +userTeamId === +item.id);

      let info = first(current);
      return (
        <MDBRow title={`My team ${info?.name}`}>
          <MDBCol size="6" sm="10" color="secondary">
            <MDBBtn color="secondary">My team {info?.name}</MDBBtn>
          </MDBCol>
        </MDBRow>
      );
    }

    return (
      <>
        <div style={{ border: "1px solid gray" }} />
        <MDBRow>
          <MDBCol size="6" sm="9">
            Select teams
          </MDBCol>
        </MDBRow>
        {(teams ?? []).map((item) => {
          return (
            <AssignTaskToTeam
              info={item}
              canModify={true}
              handleUseTeam={(teamId, checked) =>
                handleUseTeam(teamId, checked)
              }
            />
          );
        })}
      </>
    );
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          title="Name of task"
          defaultValue={taskName}
          onChange={(evt) => setTaskName(evt.target.value)}
        />
        <MDBRow>
          <MDBCol sm="12" style={{ textAlign: "left" }}>
            <MDBCol
              sm="12"
              style={{
                margin: 10,
                marginTop: -15,
                marginBottom: 5,
                textAlign: "left",
              }}
            >
              <label className="  text-sm dark:text-white text-gray-500">
                Plan completed date
              </label>
            </MDBCol>
          </MDBCol>
          <MDBCol size="6" sm="6">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </MDBCol>
          <MDBCol size="6" sm="6">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="6" sm="9">
            Status
          </MDBCol>
        </MDBRow>
        {(statuses ?? []).map((item) => {
          return (
            <MDBRow style={{ marginBottom: 10 }}>
              <MDBCol size="6" sm="9">
                {item?.statusName}
              </MDBCol>
              <MDBCol size="6" sm="3">
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  label="Current"
                  checked={statusName === item?.statusName}
                  onChange={(e) =>
                    handleCurrent(item.statusName, e.target.checked)
                  }
                />
              </MDBCol>
            </MDBRow>
          );
        })}
        <div style={{ border: "1px solid gray", marginBottom: 10 }} />
        <MDBRow>
          <MDBCol size="6" sm="3">
            <MDBInput
              className="mb-3"
              type="number"
              label="Estimate"
              title="Estimate Hours"
              defaultValue={estimateHours}
              style={{ padding: 5 }}
              onChange={(evt) => setEstimateHours(evt.target.value)}
            />
          </MDBCol>
          <MDBCol size="6" sm="3">
            <MDBInput
              className="mb-3"
              type="number"
              label="Priority"
              defaultValue={priorityNumber}
              style={{ padding: 5 }}
              onChange={(evt) => setPriorityNumber(evt.target.value)}
            />
          </MDBCol>
          <MDBCol size="6" sm="6">
            <MDBSwitch
              id="flexSwitchCheckDefault"
              label={"Show In Menu"}
              style={{ marginTop: 5 }}
              onChange={(e) => handleShowInMenu(e.target.checked)}
            ></MDBSwitch>
          </MDBCol>
        </MDBRow>

        {renderTeamByUserRole()}

        <MDBTextArea
          className="mb-3"
          label="Description"
          id="textAreaDescription"
          rows="{4}"
          style={{ marginTop: 20 }}
          defaultValue={description}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  return (
    <>
      <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>New Task of {phaseName}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={onCloseModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>{renderBody()}</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={onCloseModal}>
                Close
              </MDBBtn>
              <MDBBtn onClick={() => saveData()}>Create a New Task</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer />
    </>

  );
}

export default CreateNewTask;
