import { AgGridReact } from "ag-grid-react";
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import UpdateDisplayName from "../Planning/UpdateDisplayName";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { orderBy } from "lodash";
import SetLeaderTeam from "./SetLeaderTeam";

function TeamList() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState();
  const [teams, setTeams] = useState();
  const [displayName, setDisplayName] = useState();
  const [teamName, setTeamName] = useState();
  const [teamId, setTeamId] = useState();
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);
  const [openChangeLeaderModal, setOpenChangeLeaderModal] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Team member settings";
  }, []);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
    reloadAllTeams();
  }, []);

  const handleBlockTeam = (id, name) => {
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/Teams/${id}/Disable`,
          {},
          configHeader()
        )
        .then(() => {
          reloadAllTeams();
          toast.success(`Your request disable ${name} was successful.`);
        });
  };

  const handleActiveTeam = (id, name) => {
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/Teams/${id}/Active`,
          {},
          configHeader()
        )
        .then(() => {
          reloadAllTeams();
          toast.success(`Your request active ${name} was successful.`);
        });
  };

  const handleSetLeaderTeam = (id) => {
    setOpenChangeLeaderModal(true);
    setTeamId(id);
  };

  const reloadAllTeams = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/All-Teams`,
        configHeader()
      )
      .then((result) => {
        setTeams(result?.data ?? []);
      });
  };

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "teamleader",
      headerName: "Team leader",
      cellStyle: {
        textAlign: "left",
      },
      width: 275,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 95,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="edit"
            onClick={() => handleEditTeam(row.data.id, row.data.name)}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Active",
      width: 95,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        if (!row.data.leaderId) return <></>;
        if (row.data.isActive)
          return (
            <MDBIcon
              fas
              icon="users"
              title={`Disable team ${row.data.name}`}
              color="info"
              onClick={() => handleBlockTeam(row.data.id, row.data.name)}
            />
          );

        return (
          <MDBIcon
            fas
            icon="cookie"
            title={`Active team ${row.data.name}`}
            color="danger"
            onClick={() => handleActiveTeam(row.data.id, row.data.name)}
          />
        );
      },
    },
    {
      field: "leader",
      headerName: "Set leader",
      width: 175,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="user-tie"
            onClick={() => handleSetLeaderTeam(row.data.id)}
          />
        );
      },
    },
  ];

  const handleEditTeam = (id, teamName) => {
    setTeamId(id);
    setTeamName(teamName);
    setOpenChangeNameModal(true);
  };

  const saveData = () => {
    if ((displayName ?? "").length < 1) {
      toast.error("Team name is required.");
      return;
    }

    axios
      .post(
        `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/Teams`,
        { name: displayName },
        configHeader()
      )
      .then(() => {
        reloadAllTeams();
        toast.success(`New team ${displayName} was changed successful.`);
      });
  };

  const updateTeamName = () => {
    teamId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/Teams/${teamId}`,
          {
            id: teamId,
            name: teamName,
          },
          configHeader()
        )
        .then(() => {
          reloadAllTeams();
          setOpenChangeNameModal(false);
          toast.success("Team was changed successful.");
        });
  };

  const updateTeamLeader = (teamId, leaderId) => {
    leaderId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/Users/Teams/${teamId}/UpdateLeader/${leaderId}`,
          {
            id: teamId,
          },
          configHeader()
        )
        .then(() => {
          reloadAllTeams();
          setOpenChangeLeaderModal(false);
          toast.success("Team was changed leader successful.");
        });
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const renderTeams = () => {
    if (userRole !== "Admin") {
      return <></>;
    }

    return (
      <>
        {openChangeNameModal && (
          <UpdateDisplayName
            setIsOpenModal={setOpenChangeNameModal}
            displayName={teamName}
            setDisplayName={setTeamName}
            updateDisplayName={updateTeamName}
          />
        )}
        {openChangeLeaderModal && teamId && (
          <SetLeaderTeam
            setIsOpenModal={setOpenChangeLeaderModal}
            teamId={teamId}
            saveData={(teamId, leaderId) => updateTeamLeader(teamId, leaderId)}
          />
        )}
        <div className="ag-theme-quartz" style={{ height: "45vh" }}>
          <AgGridReact
            rowData={orderBy(teams, "isActive", "desc")}
            columnDefs={colDefs}
            fullWidthCellRenderer
            onGridReady={onGridReady}
          />
        </div>
        <MDBRow style={{ marginTop: 10 }}>
          <MDBCol size="3" sm="9">
            <MDBInput
              className="mb-3"
              type="text"
              id="displayName"
              label="Team name"
              defaultValue={displayName}
              style={{ padding: 5 }}
              onChange={(evt) => setDisplayName(evt.target.value)}
            />
          </MDBCol>
          <MDBCol size="3" sm="3">
            <MDBBtn onClick={() => saveData()} color="info">
              Save
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  return (
    <>
      {renderTeams()}
      <ToastContainer />
    </>
  );
}

export default TeamList;
