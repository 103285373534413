export const apiEndpoint = {
  hosting: "https://spaceeng.info.vn", // "https://localhost:44349", // 
  loginUrl: "Account/Issue-Certified",
  projectUrl: "Projects",
  phaseUrl: "Phases",
  taskUrl: "Tasks",
  userUrl: "Users",
  matterUrl: "MatterData",
  dashboardUrl: "Dashboard",
  companyUrl: "Companies",
  invoiceUrl: "Invoices",
};
