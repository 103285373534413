import Menu from "./Menu";

const Layout = ({ children }) => {
  return (
    <header>
      <Menu hasAvatar />
      <div className="p-5 text-center bg-light">{children}</div>
    </header>
  );
};

export default Layout;
