import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSwitch,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";

function CreatePhase({
  projectId,
  projectName,
  statuses,
  onCloseModal,
  onSave,
}) {
  const navigate = useNavigate();
  const [phaseName, setPhaseName] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [statusIds, setStatusIds] = useState([]);
  const [statusFinish, setStatusFinish] = useState();
  const [priorityNumber, setPriorityNumber] = useState();
  const [description, setDescription] = useState();
  const [statusName, setStatusName] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  const handleUse = (id, checked) => {
    if (checked) {
      let ids = statusIds;
      let hasVal = (statusIds ?? []).filter((it) => it === id);
      if ((hasVal ?? []).length < 1) {
        ids.push(id);
      }
      setStatusIds(ids);
    } else {
      let ids = statusIds.filter((it) => it !== id);
      setStatusIds(ids);
    }
  };

  const handleFinished = (id, checked) => {
    if (checked) {
      setStatusFinish(id);
    }
  };

  const handleCurrent = (name, checked) => {
    if (checked) {
      setStatusName(name);
    }
  };

  const saveData = () => {
    onSave({
      statusFinish: statusFinish,
      name: phaseName,
      statusIds: statusIds,
      startDate: startDate,
      endDate: endDate,
      priorityNumber: priorityNumber,
      projectId: projectId,
      statusName: statusName,
      description: description,
    });
    onCloseModal();
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          defaultValue={phaseName}
          onChange={(evt) => setPhaseName(evt.target.value)}
        />
        <MDBRow>
          <MDBCol sm="12" style={{ textAlign: "left" }}>
            <MDBCol
              sm="12"
              style={{
                margin: 10,
                marginTop: -15,
                marginBottom: 5,
                textAlign: "left",
              }}
            >
              <label className="  text-sm dark:text-white text-gray-500">
                Plan completed date
              </label>
            </MDBCol>
          </MDBCol>
          <MDBCol size="6" sm="6">
            <DatePicker
              selected={startDate}
              className="css-from-date-project"
              onChange={(date) => setStartDate(date)}
            />
          </MDBCol>
          <MDBCol size="6" sm="6">
            <DatePicker
              selected={endDate}
              className="css-from-date-project"
              onChange={(date) => setEndDate(date)}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="6" sm="9">
            Status
          </MDBCol>
        </MDBRow>
        {(statuses ?? []).map((item) => {
          return (
            <MDBRow style={{ marginBottom: 10 }}>
              <MDBCol size="6" sm="1">
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  onChange={(e) => handleUse(item.id, e.target.checked)}
                />
              </MDBCol>
              <MDBCol size="6" sm="5">
                {item?.statusName}
              </MDBCol>
              <MDBCol size="6" sm="3">
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  label="Current"
                  checked={statusName === item?.statusName}
                  disabled={(statusIds?? []).filter((it) => it === item.id)?.length < 1}
                  onChange={(e) =>
                    handleCurrent(item.statusName, e.target.checked)
                  }
                />
              </MDBCol>
              <MDBCol size="6" sm="3">
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  label="Finished"
                  checked={statusFinish === item?.statusName}
                  disabled={(statusIds?? []).filter((it) => it === item.id)?.length < 1}
                  onChange={(e) => handleFinished(item.id, e.target.checked)}
                />
              </MDBCol>
            </MDBRow>
          );
        })}
        <MDBRow>
          <MDBCol size="6" sm="6">
            <MDBInput
              className="mb-3"
              type="number"
              label="Priority"
              defaultValue={priorityNumber}
              style={{ padding: 5 }}
              onChange={(evt) => setPriorityNumber(evt.target.value)}
            />
          </MDBCol>
        </MDBRow>
        <MDBTextArea
          className="mb-3"
          label="Description"
          id="textAreaExample"
          rows="{4}"
          defaultValue={description}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>New Phase of {projectName}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>{renderBody()}</MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}>Create a New Phase</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default CreatePhase;
