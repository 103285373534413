import React, { useState } from "react";
import {
  MDBCol,
  MDBRow,
  MDBSwitch,
} from "mdb-react-ui-kit";
import "./Project.css";

function CheckListItem({ isChecked, displayName, handleChange }) {
  const [showCheckList, setShowCheckList] = useState(isChecked);

  const handleSwitchCheckDefault = (checked) => {
    setShowCheckList(checked);
    handleChange(checked);
  };

  return (
    <>
      <MDBRow>
        <MDBCol size="3" sm="1">
          <MDBSwitch
            id="flexSwitchCheckDefault"
            checked={showCheckList}
            style={{ cursor: "pointer" }}
            onChange={(e) => handleSwitchCheckDefault(e.target.checked)}
          />
        </MDBCol>
        <MDBCol size="3" sm="10">
          {displayName}
        </MDBCol>
      </MDBRow>
    </>
  );
}

export default CheckListItem;
