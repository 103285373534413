import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";
import InputCheckList from "./InputCheckList";
import "./CheckList.css";

const CheckList = () => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState();
  const [myTeamId, setMyTeamId] = useState();
  const [teams, setTeams] = useState();
  const [checkListOfTeam, setCheckListOfTeam] = useState();
  const [showCheckList, setShowCheckList] = useState();
  const [showInput, setShowInput] = useState(false);
  const [name, setName] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Checklist Template";
  }, []);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
    setMyTeamId(infoObj.teamId);
    setShowCheckList(infoObj.role !== "Admin");
  }, []);

  useEffect(() => {
    reloadAllCheckListMyTeam(myTeamId);
  }, [myTeamId]);

  useEffect(() => {
    reloadAllListOfTeams(userRole);
  }, [userRole]);

  const reloadAllListOfTeams = (userRole) => {
    if (userRole !== "Admin") return;

    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/All-Teams`,
        configHeader()
      )
      .then((result) => {
        setTeams(result?.data ?? []);
      });
  };

  const reloadAllCheckListMyTeam = (myTeamId) => {
    myTeamId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/CheckLists/${myTeamId}`,
          configHeader()
        )
        .then((result) => {
          setCheckListOfTeam(result?.data ?? []);
        });
  };

  const handleSaveData = (data) => {
    if (data.name?.length < 1) {
      toast.error(
        "Checklist name is empty, please input the name is required."
      );
      return;
    }
    axios
      .post(`${apiEndpoint.hosting}/api/CheckLists`, data, configHeader())
      .then(() => {
        setCheckListOfTeam([]);
        reloadAllCheckListMyTeam(myTeamId);
        toast.success("Your checklist has been created.");
      });
  };

  const handleModifyData = (id, data) => {
    if (data.name?.length < 1) {
      toast.error(
        "Checklist name is empty, please input the name is required."
      );
      return;
    }

    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/CheckLists/${id}`,
          data,
          configHeader()
        )
        .then(() => {
          setCheckListOfTeam([]);
          reloadAllCheckListMyTeam(myTeamId);
          toast.success("Your checklist has been updated.");
        });
  };

  const handleDeleteData = (id, myTeamId, listType) => {
    id &&
      axios
        .delete(
          `${apiEndpoint.hosting}/api/CheckLists/${id}/Teams/${myTeamId}/ListTypes/${listType}`,
          configHeader()
        )
        .then(() => {
          setCheckListOfTeam([]);
          reloadAllCheckListMyTeam(myTeamId);
          toast.success("Your checklist has been deleted.");
        });
  };

  const handleAdd = () => {
    setShowInput(true);
    setName();
  };

  const saveData = () => {};

  const renderControlInput = () => {
    return (
      <MDBRow style={{ margin: 15 }}>
        <MDBCol size="3" sm="1">
          <MDBIcon fas icon="file-alt" style={{ alignItems: "end" }} />
        </MDBCol>
        <MDBCol size="3" sm="8">
          <MDBInput
            type="text"
            label="Name"
            defaultValue={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </MDBCol>
        <MDBCol sm="3">
          <MDBBtn onClick={() => saveData()}>
            <MDBIcon fas icon="save" />
          </MDBBtn>
          <MDBBtn
            onClick={() => setShowInput(false)}
            style={{ marginLeft: 10 }}
            color="warning"
          >
            <MDBIcon fas icon="ban" />
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    );
  };

  const renderCheckListOfTeam = () => {
    return (
      <>
        <MDBRow style={{ textAlign: "left", margin: 15 }}>
          <MDBIcon
            fas
            icon="plus"
            color="primary"
            onClick={() => handleAdd()}
            title={`Add new checklist`}
          />
          {showInput && renderControlInput()}
        </MDBRow>

        {(checkListOfTeam ?? []).map((item, idx) => {
          return (
            <MDBAccordion initialActive={1}>
              <MDBAccordionItem collapseId={item?.id} headerTitle={item?.name}>
                <MDBRow>
                  <MDBCol sm="10" style={{ textAlign: "left", margin: 15 }}>
                    <InputCheckList
                      displayName={item?.name}
                      myTeamId={myTeamId}
                      parentId={item?.id}
                      listType={2}
                      handleSaveData={(data) => handleSaveData(data)}
                      handleModifyData={(data) =>
                        handleModifyData(item?.id, data)
                      }
                      handleDeleteData={() =>
                        handleDeleteData(item?.id, myTeamId, 1)
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="3" sm="1"></MDBCol>
                  <MDBCol size="3" sm="10">
                    {(item?.paragraphs).map((p) => {
                      return (
                        <>
                          <InputCheckList
                            displayName={p?.name}
                            myTeamId={myTeamId}
                            parentId={p?.id}
                            listType={3}
                            handleSaveData={(data) => handleSaveData(data)}
                            handleModifyData={(data) =>
                              handleModifyData(p?.id, data)
                            }
                            handleDeleteData={() =>
                              handleDeleteData(p?.id, myTeamId, 2)
                            }
                          />
                          <MDBRow>
                            <MDBCol size="3" sm="1"></MDBCol>
                            <MDBCol size="3" sm="10">
                              {(p?.headings).map((h) => {
                                return (
                                  <>
                                    <InputCheckList
                                      displayName={h?.name}
                                      myTeamId={myTeamId}
                                      parentId={h?.id}
                                      listType={4}
                                      handleSaveData={(data) =>
                                        handleSaveData(data)
                                      }
                                      handleModifyData={(data) =>
                                        handleModifyData(h?.id, data)
                                      }
                                      handleDeleteData={() =>
                                        handleDeleteData(h?.id, myTeamId, 3)
                                      }
                                    />

                                    {(h?.items).map((sub) => {
                                      return (
                                        <MDBRow>
                                          <MDBCol size="3" sm="1"></MDBCol>
                                          <MDBCol
                                            sm="9"
                                            style={{
                                              textAlign: "left",
                                              margin: 15,
                                            }}
                                            className=" truncate max-w-[450px]"
                                          >
                                            <InputCheckList
                                              displayName={sub?.name}
                                              myTeamId={myTeamId}
                                              parentId={sub?.id}
                                              listType={5}
                                              onlyModify
                                              handleModifyData={(data) =>
                                                handleModifyData(sub?.id, data)
                                              }
                                              handleDeleteData={() =>
                                                handleDeleteData(
                                                  sub?.id,
                                                  myTeamId,
                                                  4
                                                )
                                              }
                                            />
                                          </MDBCol>
                                        </MDBRow>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </MDBCol>
                          </MDBRow>
                        </>
                      );
                    })}
                  </MDBCol>
                </MDBRow>
              </MDBAccordionItem>
            </MDBAccordion>
          );
        })}
      </>
    );
  };

  const setTeamCtrl = (teamId) => {
    setMyTeamId(teamId);
    setCheckListOfTeam([]);
    reloadAllCheckListMyTeam(teamId);
    setShowCheckList(true);
  };

  const setHideTeamCtrl = () => {
    setShowCheckList(false);
    setMyTeamId(0);
    setCheckListOfTeam([]);
  };

  const renderTeamCtrl = (teams) => {
    return (
      <>
        {(teams ?? [])
          .filter((item) => item?.isActive)
          .map((item) => {
            let iconView = (
              <MDBIcon
                fas
                icon="arrow-circle-down"
                style={{ marginLeft: 50, cursor: "pointer" }}
                onClick={() => setTeamCtrl(item?.id)}
              />
            );

            let lblView = item?.name;
            if (showCheckList && myTeamId === item?.id) {
              iconView = (
                <MDBIcon
                  fas
                  icon="arrow-circle-up"
                  color="info"
                  style={{ marginLeft: 50, cursor: "pointer" }}
                  onClick={() => setHideTeamCtrl()}
                />
              );

              lblView = (
                <div className=" truncate max-w-[300px] font-bold font-sans  ">
                  {item?.name} {iconView}
                </div>
              );
            }

            return (
              <MDBRow
                style={{ padding: showCheckList ? 5 : 20 }}
                title={item?.name}
              >
                <MDBCol sm="1">
                  <MDBIcon fas icon="users" />
                </MDBCol>
                <MDBCol size="3" sm="10">
                  {lblView} {myTeamId !== item?.id && iconView}
                </MDBCol>
              </MDBRow>
            );
          })}
      </>
    );
  };

  return (
    <div className="divScroll">
      {teams && renderTeamCtrl(teams)}
      {showCheckList && renderCheckListOfTeam()}
      <ToastContainer />
    </div>
  );
};

export default CheckList;
