import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSwitch,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { first } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const statusOfSubTask = [
  { id: 153, statusName: "To do" },
  { id: 154, statusName: "Working" },
  { id: 155, statusName: "Done" },
];

function CreateNewSubTask({
  taskId,
  teamId,
  taskName,
  staffs,
  onCloseModal,
  onSave,
}) {
  const navigate = useNavigate();
  const [subTaskName, setSubTaskName] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [priorityNumber, setPriorityNumber] = useState();
  const [description, setDescription] = useState();
  const [statusName, setStatusName] = useState("To do");
  const [estimateHours, setEstimateHours] = useState();
  const [userId, setUserId] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  useEffect(() => {
    if (staffs?.length === 1) {
      let pro = first(staffs ?? []) ?? {};
      setUserId(pro?.id);
    }
  }, [staffs]);

  const handleCurrent = (name, checked) => {
    if (checked) {
      setStatusName(name);
    }
  };

  const validateDataBeforeSave = () => {
    if (userId < 1) {
      toast.warning("Please select a staff for this assignment");
      return false;
    }

    if (taskId < 1) {
      toast.warning("Please select task again.");
      return false;
    }

    if ((subTaskName ?? "").length < 1) {
      toast.warning("Name is required, please input this field.");
      return false;
    }

    return true;
  };

  const saveData = () => {
    let valid = validateDataBeforeSave();
    if (valid) {
      onSave({
        name: subTaskName,
        estimateHours: estimateHours,
        startDate: startDate,
        endDate: endDate,
        priorityNumber: priorityNumber,
        taskId: taskId,
        teamId: teamId,
        assignedId: userId,
        statusName: statusName,
        description: description,
      });
      onCloseModal();
    }
  };

  const options = () => {
    return (staffs ?? [])
      .filter((s) => s.teamId === teamId)
      .map((p) => {
        return { name: p.displayName, id: p.id };
      });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (staffs ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setUserId(pro?.id);
  };

  const renderStatus = (item) => {
    return (
      <MDBCol size="3" sm="4">
        <MDBRow>
          <MDBCol size="6" sm="3">
            <MDBSwitch
              id="flexSwitchCheckDefault"
              checked={statusName === item?.statusName}
              onChange={(e) => handleCurrent(item.statusName, e.target.checked)}
            />
          </MDBCol>
          <MDBCol size="6" sm="9" className=" truncate max-w-[150px]">
            {item?.statusName}
          </MDBCol>
        </MDBRow>
      </MDBCol>
    );
  };

  const renderSubTaskStatus = () => {
    return (
      <MDBRow
        style={{ marginBottom: 10, marginTop: 15 }}
        title="Select a status for this sub task"
      >
        {(statusOfSubTask ?? []).map((item) => renderStatus(item))}
      </MDBRow>
    );
  };

  const renderSubTaskEst = () => {
    return (
      <MDBRow style={{ marginBottom: 10, marginTop: 15 }}>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            label="Estimate"
            title="Estimate Hours"
            defaultValue={estimateHours}
            style={{ padding: 5 }}
            onChange={(evt) => setEstimateHours(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            label="Priority"
            defaultValue={priorityNumber}
            style={{ padding: 5 }}
            onChange={(evt) => setPriorityNumber(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
    );
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          title="Name of sub task"
          defaultValue={subTaskName}
          onChange={(evt) => setSubTaskName(evt.target.value)}
        />
        <MDBRow>
          <MDBCol sm="12" style={{ textAlign: "left" }}>
            <MDBCol
              sm="12"
              style={{
                margin: 10,
                //marginTop: -15,
                marginBottom: 5,
                textAlign: "left",
              }}
            >
              <label className="  text-sm dark:text-white text-gray-500">
                Plan completed date
              </label>
            </MDBCol>
          </MDBCol>
          <MDBCol size="6" sm="6">
            <DatePicker
              selected={startDate}
              className="css-from-date-project"
              onChange={(date) => setStartDate(date)}
            />
          </MDBCol>
          <MDBCol size="6" sm="6">
            <DatePicker
              selected={endDate}
              className="css-from-date-project"
              onChange={(date) => setEndDate(date)}
            />
          </MDBCol>
        </MDBRow>
        <div style={{ border: "1px solid gray", marginTop: 15 }} />
        {renderSubTaskStatus()}
        <div style={{ border: "1px solid gray" }} />
        {renderSubTaskEst()}
        <div style={{ border: "1px solid gray" }} />
        <MDBRow title={"Assign to Staff"} style={{ marginTop: 15 }}>
          <MDBCol size="6" sm="12">
            <Multiselect
              options={options()}
              singleSelect
              placeholder="Select a staff"
              label="Assign to Staff"
              onSelect={(items) => onSelectedValues(items)}
              onRemove={(items) => onSelectedValues(items)}
              displayValue="name"
            />
          </MDBCol>
        </MDBRow>
        <MDBTextArea
          className="mb-3"
          label="Description"
          id="textAreaDescription"
          rows="{4}"
          style={{ marginTop: 20 }}
          defaultValue={description}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>New Sub Task of {taskName}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>{renderBody()}</MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}>Create a New Task</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
      <ToastContainer />
    </MDBModal>
  );
}

export default CreateNewSubTask;
