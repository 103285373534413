import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import PhaseMenuItem from "./PhaseMenuItem";
import { orderBy } from "lodash";
import { useState } from "react";

function ProjectInfo({
  project,
  projectId,
  currentPhaseId,
  taskInfoId,
  userRole,
  onSelectPhase,
  onSelectTask,
  onSelectProject,
  onShowCheckListOfPhase,
}) {
  const [showProject, setShowProject] = useState(false);
  const toggleProject = () => setShowProject(!showProject);

  const renderItem = () => {
    if ((project?.phases ?? []).length < 1) return <></>;
    return (
      <MDBBtn
        onClick={toggleProject}
        color="tertiary"
        style={{ marginLeft: 10 }}
      >
        {!showProject && <MDBIcon fas icon="caret-down" />}
        {showProject && <MDBIcon fas icon="caret-up" />}
      </MDBBtn>
    );
  };

  const renderPhaseMenus = () => {
    return (
      <MDBRow style={{ padding: 15 }}>
        {orderBy(project?.phases ?? [], "priorityNumber").map((phase) => (
          <PhaseMenuItem
            phase={phase}
            currentId={currentPhaseId}
            taskInfoId={taskInfoId}
            onSelectPhase={onSelectPhase}
            onSelectTask={onSelectTask}
            userRole={userRole}
            onShowCheckListOfPhase={onShowCheckListOfPhase}
          />
        ))}
      </MDBRow>
    );
  };

  const renderMainBody = () => {
    return (
      <>      
        <MDBRow style={{ padding: 15 }}>
          <MDBCol sm="9">
            <MDBBtn
              style={{ cursor: "pointer", borderRadius: 16 }}
              color={projectId === project?.id ? "info" : "tertiary"}
              title={`[${project?.name}] ${project?.description}`}
            >
              <label
                className=" truncate max-w-[125px]"
                onClick={() => onSelectProject(project)}
              >
                {project?.name}
              </label>
            </MDBBtn>
          </MDBCol>
          <MDBCol size="3" sm="3">
            {renderItem()}
          </MDBCol>
          {showProject && renderPhaseMenus()}
        </MDBRow>
      </>

    );
  };

  return renderMainBody();
  // <MDBAccordionItem
  //   collapseId={index}
  //   headerTitle={project?.name}
  // ></MDBAccordionItem>
}

export default ProjectInfo;
