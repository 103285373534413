import { MDBCol, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import { useState } from "react";

function AssignTaskToTeam({ info, handleUseTeam, canModify, isChecked }) {

  const [showChecked, setShowChecked] = useState(isChecked);

  const handleCheckTeam = (checked) => {
    setShowChecked(checked)
    handleUseTeam(info?.id, checked);
  }

  return (
    <MDBRow>
      <MDBCol size="6" sm="2">
        <MDBSwitch
          id="flexSwitchCheckDefault"
          readOnly={!canModify}
          checked={showChecked}
          onChange={(e) => handleCheckTeam(e.target.checked)}
        />
      </MDBCol>
      <MDBCol size="6" sm="9">
        {info?.name}
      </MDBCol>
    </MDBRow>
  );
}

export default AssignTaskToTeam;
