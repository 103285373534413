import { AgGridReact } from "ag-grid-react";
import { MDBCard, MDBCardBody, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onGridReady, toCommas, toThousands } from "../../utils/utilities";
import axios from "axios";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { orderBy } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateBudget from "./CreateBudget";
import ViewBudgetInfo from "./ViewBudgetInfo";
import { Label } from "reactstrap";

function PhaseBudget() {
  const navigate = useNavigate();
  const [teams, setTeams] = useState();
  const [budgets, setBudgets] = useState();
  const [budget, setBudget] = useState();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "List of Budgets";
  }, []);

  useEffect(() => {
    reloadBudgets();
    reloadAllListOfTeams();
  }, []);

  const reloadAllListOfTeams = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/All-Teams`,
        configHeader()
      )
      .then((result) => {
        let items = (result?.data ?? []).filter((item) => item?.isActive)
        setTeams(items ?? []);
      });
  };

  const reloadBudgets = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/Budgets`,
        configHeader()
      )
      .then((result) => {
        let items = (result?.data ?? []).filter((item) => item?.projectName)
        setBudgets(items ?? []);
      });
  };

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "projectName",
      headerName: "Project Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
    },
    {
      field: "phaseName",
      headerName: "Phase Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 350,
    },
    {
      field: "teamBudgets",
      headerName: "Budgets",
      width: 315,
      cellRenderer: (row) => <ViewBudgetInfo data={row.data} />
    },
    {
      field: "budgetAmount",
      headerName: "Budget Amount",
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => {
        let divBudgets = toThousands(row.data?.budgetAmount);
        let divTeams = toCommas(row.data?.budgetAmount);
        return <Label title={divTeams}>{divBudgets}</Label>
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 95,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="edit"
            onClick={() => handleEditBudget(row.data)}
          />
        );
      },
    }
  ];

  const handleEditBudget = (budget) => {
    setBudget(budget);
    setOpenModal(true);
  }

  const handleSaveData = (data) => {
    if (data?.phaseId < 1) {
      toast.error(
        "Your phase is empty, please select a phase."
      );
      return;
    }

    axios
      .post(
        `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/${data?.phaseId}/bugdet`, data,
        configHeader()
      )
      .then(() => {
        reloadBudgets();
        setOpenModal(false);
        setBudget({})
        toast.success("Your Budgets has been created.");
      });
  }

  return <>
    <MDBCard>
      <MDBCardBody>
        {openModal &&
          <CreateBudget
            budget={budget}
            teams={teams}
            onCloseModal={() => setOpenModal(false)}
            onSave={(data) => handleSaveData(data)}
          />}
        <MDBRow>
          <lable className="displayEnd">One thousand in Vietnamese (1000 VND)</lable>
        </MDBRow>

        <div className="ag-theme-quartz" style={{ height: "65vh", marginTop: 25 }}>
          <AgGridReact
            rowData={orderBy(budgets, "name")}
            columnDefs={colDefs}
            fullWidthCellRenderer
            onGridReady={onGridReady}
          />
        </div>
      </MDBCardBody>
    </MDBCard>
    <ToastContainer />
  </>;
}

export default PhaseBudget;
