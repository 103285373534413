import { MDBCol, MDBInput, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import { useState } from "react";

function AssignBudgetToTeam({ info, budgetPercent, handleUseTeam, handleInputPercent, isChecked }) {
  const [currentTeam, setCurrentTeam] = useState(isChecked);

  const handleChange = (value) => {
    if (value > 0) {
      setCurrentTeam(value > 0)
      handleUseTeam(info?.id, value > 0)
    }
    handleInputPercent(info?.id, value)
  }

  return (
    <MDBRow>
      <MDBCol size="6" sm="2">
        <MDBSwitch
          id="flexSwitchCheckDefault"
          checked={currentTeam}
          onChange={(e) => handleUseTeam(info?.id, e.target.checked)}
        />
      </MDBCol>
      <MDBCol size="6" sm="6">
        {info?.name}
      </MDBCol>
      <MDBCol size="6" sm="3">
        <MDBInput
          className="mb-3"
          type="number"
          id="budgetPercent"
          label="Budget %"
          value={budgetPercent}
          style={{ padding: 5, textAlign: "right", marginRight: 15 }}
          onChange={(evt) => handleChange(evt.target.value)}
        />
      </MDBCol>
    </MDBRow>
  );
}

export default AssignBudgetToTeam;
