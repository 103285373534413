import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { orderBy } from "lodash";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import CreateProject from "../../modals/CreateProject";
import DisplayUser from "../../components/DisplayUser";
import EditProject from "../../modals/EditProject";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dateFormatDMY, onGridReady } from "../../utils/utilities";

const Grids = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState();
  const [projectId, setProjectId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const [statuses, setStatuses] = useState();
  const [userRole, setUserRole] = useState();
  const [projectInfo, setProjectInfo] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Project List";
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
  }, []);

  useEffect(() => {
    reloadAllListOfProjects();
    reloadAllListOfStatus();
  }, []);

  const reloadAllListOfProjects = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = result?.data ?? [];
        setProjects(orderBy(items, "priorityNumber"));
      });
  };

  const reloadAllListOfStatus = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/All-Status`,
        configHeader()
      )
      .then((result) => {
        setStatuses(result?.data ?? []);
      });
  };

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
    },
    { field: "priorityNumber", width: 75 },
    {
      field: "statusName",
      cellStyle: {
        textAlign: "left",
      },
      width: 75,
    },
    {
      field: "actions",
      headerName: "Finish",
      width: 75,
      cellRenderer: (row) => {
        if (row.data?.isFinished) return <></>;
        return (
          <MDBIcon
            fas
            icon="hourglass-end"
            style={{ cursor: "pointer" }}
            onClick={() => handleFinishProject(row.data.id)}
            title={`Set Finish Project ${row.data.name}`}
          />
        );
      },
    },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "estimateHours",
      width: 95,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: (data) => {
        return !data?.value ? "" : data?.value;
      },
    },
    {
      field: "approvalBy",
      cellRenderer: (data) => {
        return <DisplayUser userName={data?.value} />;
      },
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 75,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="edit"
            style={{ cursor: "pointer" }}
            onClick={() => handleEditProject(row.data)}
          />
        );
      },
    },
    {
      field: "description",
      cellStyle: {
        textAlign: "left",
      },
      width: 375,
    },
  ];

  const handleEditProject = (data) => {
    setProjectId(data?.id);
    setProjectInfo(data);
    setPopupEdit(true);
  };

  const handleNewProject = () => {
    setProjectId(0);
    setModalOpen(true);
  };

  const createSiteBar = () => {
    if (userRole !== "Admin" && userRole !== "Manager") return <></>;
    return (
      <div class="p-1 text-left bg-light">
        <MDBBtn
          onClick={() => handleNewProject()}
          style={{ cursor: "pointer" }}
        >
          Create a new project
        </MDBBtn>
      </div>
    );
  };

  const onSaveProject = (data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/api/${apiEndpoint.projectUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        reloadAllListOfProjects();
      });
  };

  const onUpdateProject = (data) => {
    projectId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.projectUrl}/${projectId}/BasicInfo`,
          data,
          configHeader()
        )
        .then(() => {
          reloadAllListOfProjects();
          toast.success("Your project has been updated successful.");
        });
  };

  const handleFinishProject = (id) => {
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.projectUrl}/${id}/Finished`,
          {},
          configHeader()
        )
        .then(() => {
          reloadAllListOfProjects();
          toast.success("Your project has been finished.");
        });
  };

  return (
    <>
      {createSiteBar()}
      <div className="ag-theme-quartz" style={{ height: "75vh" }}>
        <AgGridReact
          rowData={orderBy(projects, "priorityNumber")}
          columnDefs={colDefs}
          onGridReady={onGridReady}
        />
      </div>

      {modalOpen && (
        <CreateProject
          statuses={statuses ?? []}
          onCloseModal={() => setModalOpen(false)}
          onSave={(data) => onSaveProject(data)}
        />
      )}

      {popupEdit && (
        <EditProject
          statuses={statuses ?? []}
          onCloseModal={() => setPopupEdit(false)}
          onSave={(data) => onUpdateProject(data)}
          projectInfo={projectInfo}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default Grids;
