import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StatusList from "./StatusList";
import TeamList from "./TeamList";
import ReceiptSettings from "./ReceiptSettings";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { orderBy } from "lodash";
import ViewProjectInfo from "./ViewProjectInfo";

function UserSettings() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState();
  const [projects, setProjects] = useState();
  const [phases, setPhases] = useState();
  const [phaseId, setPhaseId] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "User settings";
  }, []);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
    reloadProjects()
  }, []);

  const reloadProjects = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = result?.data ?? [];
        setProjects(orderBy(items, "priorityNumber"));
      });
  };


  const handleCheckPhase = (phaseId, checked) => {

  }


  const renderStatus = () => {
    if (userRole !== "Admin") {
      return <></>;
    }

    return (
      <>
        <MDBAccordion initialActive={0}>
          <MDBAccordionItem collapseId={1} headerTitle={"Statuses"}>
            <StatusList />
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle={"Teams"}>
            <TeamList />
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={3} headerTitle={"Receipt"}>
            <ReceiptSettings />
          </MDBAccordionItem>
        </MDBAccordion>
      </>
    );
  };

  const renderProjects = () => {
    {
      (projects ?? []).map((pro) => {
        return <>
          <ViewProjectInfo data={pro}
            handleCheckPhase={(phaseId, checked) => handleCheckPhase(phaseId, checked)} />
        </>
      }
      )
    }

    return <MDBAccordion alwaysOpen initialActive={1}>
      {(projects ?? []).map((pro) => {
        return <MDBAccordionItem collapseId={pro?.id} headerTitle={pro?.name}>
          <ViewProjectInfo data={pro}
            handleCheckPhase={(phaseId, checked) => handleCheckPhase(phaseId, checked)} />
        </MDBAccordionItem>
      })}
    </MDBAccordion>
  }

  return (
    <>
      <div className="divScroll">
        <MDBAccordion alwaysOpen initialActive={1}>
          {userRole === "Admin" && <MDBAccordionItem collapseId={1} headerTitle="System settings">
            {renderStatus()}
          </MDBAccordionItem>}
          <MDBAccordionItem collapseId={2} headerTitle="Project settings">
            {renderProjects()}
          </MDBAccordionItem>
        </MDBAccordion>
      </div >
      <ToastContainer />
    </>
  );
}

export default UserSettings;
