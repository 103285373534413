import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import UpdateDisplayName from "../Planning/UpdateDisplayName";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { orderBy } from "lodash";
import UpdateRoleName from "./UpdateRoleName";
import { toCommas } from "../../utils/utilities";
import UpdatePrice from "../Planning/UpdatePrice";
import CreateUser from "./CreateUser";

function UserMembers() {
  const navigate = useNavigate();
  const [staffs, setStaffs] = useState();
  const [userId, setUserId] = useState();
  const [userRole, setUserRole] = useState();
  const [uRole, setURole] = useState();
  const [myTeamId, setMyTeamId] = useState();

  const [displayName, setDisplayName] = useState();
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);
  const [openRoleName, setOpenRoleName] = useState(false);
  const [openNewUser, setOpenNewUser] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [userPrice, setUserPrice] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Manage users";
  }, []);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setURole(infoObj.role);
    setMyTeamId(infoObj.teamId);
    loadDataStaffs();
  }, []);

  const loadDataStaffs = () => {
    axios
      .get(`${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}`, configHeader())
      .then((result) => {
        setStaffs(orderBy(result?.data, "isActive", "desc"));
      });
  };

  const handleEditName = (id, name) => {
    setUserId(id);
    setDisplayName(name);
    setOpenChangeNameModal(true);
  };

  const handlePrice = (id, displayName, pricing) => {
    setUserId(id);
    setDisplayName(displayName);
    setUserPrice(pricing);
    setOpenPriceModal(true);
  };

  const updateDisplayName = () => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${userId}/UpdateName`,
        { name: displayName },
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        setOpenChangeNameModal(false);
      });
  };

  const handleActive = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${id}/Active`,
        {},
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        toast.success(`Your request active ${name} was successful.`);
      });
  };

  const handleBlockUser = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${id}/Disable`,
        {},
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        toast.success(
          `Account of ${name} account has been disabled. The account can't log in to system.`
        );
      });
  };

  const handleResetPass = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${id}/Reset-password`,
        {},
        configHeader()
      )
      .then(() => {
        toast.success(
          `The user ${name} has been reset password to default password.`
        );
      });
  };

  const handleChangeRole = (id, name, role) => {
    setOpenRoleName(true);
    setUserId(id);
    setUserRole(role);
    setDisplayName(name);
  };

  const createUser = (data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        setOpenNewUser(false);
        loadDataStaffs();
      });
  };

  const saveChangePrice = () => {
    userId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${userId}/UpdatePrice`,
          { price: userPrice ?? 0 },
          configHeader()
        )
        .then(() => {
          loadDataStaffs();
          setOpenPriceModal(false);
          toast.success(
            `The user ${displayName} has been changed price successful.`
          );
        });
  };

  const saveChangeRole = (role) => {
    userId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/Staffs/${userId}/UpdateRole`,
          { name: role },
          configHeader()
        )
        .then(() => {
          setOpenRoleName(false);
          loadDataStaffs();
          toast.success(
            `The user ${displayName} has been change role to ${role}.`
          );
        });
  };

  const colUserDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "displayName",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        let avatar = row.data.roleName === "Admin" ? "user-cog" : "user-circle";
        if (row.data.roleName === "Teamleader") avatar = "user-alt";
        if (row.data.roleName === "Manager") avatar = "user-tie";
        let icon = !row.data.isActive ? (
          ""
        ) : (
          <MDBIcon
            fas
            icon={avatar}
            style={{ marginRight: 15 }}
            title="An individual who regularly engages with and utilizes your platform"
          />
        );
        return (
          <>
            {icon} {row.data.displayName}
          </>
        );
      },
    },
    {
      field: "team",
      cellStyle: {
        textAlign: "left",
      },
      width: 195,
    },
    {
      field: "roleName",
      headerName: "Role",
      cellStyle: {
        textAlign: "left",
      },
      width: 145,
    },
    {
      field: "actions",
      headerName: "Active user",
      width: 125,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        if (row.data.isActive)
          return (
            <MDBIcon
              fas
              icon="user-check"
              color="info"
              title={`The account of ${row.data.displayName} is Active`}
            />
          );

        return (
          <MDBIcon
            fas
            icon="user-alt-slash"
            title={`The account of ${row.data.displayName} is disable`}
            color="warning"
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Update Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="user-edit"
            title="Change display name"
            onClick={() => handleEditName(row.data.id, row.data.displayName)}
          />
        );
      },
    },
  ];

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "displayName",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        let avatar = row.data.roleName === "Admin" ? "user-cog" : "user-circle";
        if (row.data.roleName === "Teamleader") avatar = "user-alt";
        if (row.data.roleName === "Manager") avatar = "user-tie";
        let icon = !row.data.isActive ? (
          ""
        ) : (
          <MDBIcon
            fas
            icon={avatar}
            style={{ marginRight: 15 }}
            title="An individual who regularly engages with and utilizes your platform"
          />
        );
        return (
          <>
            {icon} {row.data.displayName}
          </>
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 145,
    },
    {
      field: "team",
      cellStyle: {
        textAlign: "left",
      },
      width: 195,
    },
    {
      field: "roleName",
      headerName: "Role",
      cellStyle: {
        textAlign: "left",
      },
      width: 145,
    },
    {
      field: "actions",
      headerName: "Active user",
      width: 125,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        if (row.data.isActive)
          return (
            <MDBIcon
              fas
              icon="user-check"
              color="info"
              title={`Disable your account ${row.data.displayName}`}
              onClick={() => handleBlockUser(row.data.id, row.data.displayName)}
            />
          );

        return (
          <MDBIcon
            fas
            icon="user-alt-slash"
            title={`Active account of ${row.data.displayName}`}
            color="warning"
            onClick={() => handleActive(row.data.id, row.data.displayName)}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Update Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
      cellRenderer: (row) => {
        if (!row.data.isActive) return <></>;
        return (
          <MDBIcon
            fas
            icon="user-edit"
            title="Change display name"
            onClick={() => handleEditName(row.data.id, row.data.displayName)}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Reset password",
      width: 125,
      cellRenderer: (row) => {
        if (!row.data.isActive) return <></>;
        return (
          <MDBIcon
            fas
            icon="key"
            onClick={() => handleResetPass(row.data.id, row.data.displayName)}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Change role",
      width: 125,
      cellRenderer: (row) => {
        if (!row.data.isActive) return <></>;
        return (
          <MDBIcon
            fas
            icon="cogs"
            onClick={() =>
              handleChangeRole(
                row.data.id,
                row.data.displayName,
                row.data.roleName
              )
            }
          />
        );
      },
    },
    {
      field: "pricing",
      width: 95,
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: (row) => toCommas(row.data.pricing),
    },
    {
      field: "actions",
      headerName: "Update Pricing",
      cellStyle: {
        textAlign: "right",
      },
      cellRenderer: (row) => {
        if (!row.data.isActive) return <></>;
        return (
          <MDBIcon
            fas
            icon="money-bill-alt"
            title="Change Price"
            onClick={() =>
              handlePrice(row.data.id, row.data.displayName, row.data.pricing)
            }
          />
        );
      },
    },
  ];

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <>
      {openChangeNameModal && (
        <UpdateDisplayName
          setIsOpenModal={setOpenChangeNameModal}
          displayName={displayName}
          setDisplayName={setDisplayName}
          updateDisplayName={updateDisplayName}
        />
      )}

      {openRoleName && (
        <UpdateRoleName
          setIsOpenModal={setOpenRoleName}
          roleName={userRole}
          displayName={displayName}
          saveData={(role) => saveChangeRole(role)}
        />
      )}

      {openPriceModal && (
        <UpdatePrice
          setIsOpenModal={setOpenPriceModal}
          userPrice={userPrice}
          setUserPrice={setUserPrice}
          updatePrice={() => saveChangePrice()}
        />
      )}

      {openNewUser && (
        <CreateUser
          teamId={uRole === "Admin" ? 0 : myTeamId}
          onCloseModal={() => setOpenNewUser(false)}
          onSave={(data) => createUser(data)}
        />
      )}

      <MDBRow style={{ marginTop: 10, textAlign: "left" }}>
        <MDBCol sm="12">
          <MDBBtn onClick={() => setOpenNewUser(true)} color="info">
            Add new user
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 10 }}>
        <MDBCol size="3" sm="12">
          <div className="ag-theme-quartz" style={{ height: "65vh" }}>
            <AgGridReact
              rowData={staffs}
              columnDefs={uRole === "Admin" ? colDefs : colUserDefs}
              fullWidthCellRenderer
              onGridReady={onGridReady}
            />
          </div>
        </MDBCol>
      </MDBRow>
      <ToastContainer />
    </>
  );
}

export default UserMembers;
