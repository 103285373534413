import { useEffect, useState } from "react";
import { MDBBadge, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import axios from "axios";
import { configHeader } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import CurrentLogTimeStatus from "./CurrentLogTimeStatus";
import CreateNewSubTask from "../../modals/CreateNewSubTask";
import LogWork from "./LogWork";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";

function CellSubTaskSection({
  colIndex,
  taskIndex,
  cellInfo,
  userRole,
  staffsOfPhase,
  handleDeleteSubTask,
  taskInfoId,
}) {
  const subTaskInfo = cellInfo ?? {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogTimeOpen, setIsLogTimeOpen] = useState(false);
  const [taskDetailInfo, setTaskDetailInfo] = useState();
  const [isOpenSubTaskModal, setIsOpenSubTaskModal] = useState(false);
  const [userTeamId, setUserTeamId] = useState();
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserTeamId(infoObj.teamId);
  }, []);

  useEffect(() => {
    reloadTaskDetailInfo(taskInfoId);
  }, [taskInfoId]);

  const reloadTaskDetailInfo = (taskId) => {
    taskId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/${taskId}`,
          configHeader()
        )
        .then((result) => {
          setTaskDetailInfo(result?.data);
        });
  };

  const saveData = (data) => {
    subTaskInfo?.id &&
      axios
        .post(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/${subTaskInfo.id}/LogWork`,
          data,
          configHeader()
        )
        .then(() => {
          reloadTaskDetailInfo(taskInfoId);
          setIsLogTimeOpen(false);
          setIsModalOpen(false);
          //reloadPhaseInfo(currentPhaseId);
        });
  };

  const handleOnDrag = (e) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ taskIndex, prevColIndex: colIndex })
    );
  };

  const onSaveSubTask = (data) => {
    subTaskInfo?.id &&
      axios
        .post(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/${subTaskInfo.id}/Subtask`,
          data,
          configHeader()
        )
        .then(() => {
          reloadTaskDetailInfo(taskInfoId);
          setIsOpenSubTaskModal(false);
          //reloadPhaseInfo(currentPhaseId);
        });
  };

  const renderCtrDelete = () => {
    if (userRole === "Admin" || userRole === "Manager") {
      return (
        <MDBRow>
          <MDBCol size="3" sm="12" title="Delete this subtask">
            <div
              style={{ alignItems: "flex-end" }}
              onClick={() => {
                setIsConfirmDelete(true);
              }}
            >
              <MDBIcon fas icon="trash-alt" color="warning" />
            </div>
          </MDBCol>
        </MDBRow>
      );
    }

    return <></>;
  };

  const renderLogWork = () => {
    let hasTeam =
      (taskDetailInfo?.teamIds ?? []).filter((item) => +userTeamId === +item)
        ?.length > 0;

    if (!hasTeam && userRole !== "Admin" && userRole !== "Manager")
      return <></>;

    return (
      <div onClick={() => setIsLogTimeOpen(true)}>
        <div style={{ border: "1px solid gray" }} />
        Log Working
      </div>
    );
  };

  const renderMainItem = () => {
    return (
      <div
        draggable
        onDragStart={handleOnDrag}
        className=" w-[280px] first:my-5 rounded-md  bg-white  dark:bg-[#2b2c37] shadow-[#364e7e1a] py-6 px-3 shadow-lg hover:text-[#635fc7] dark:text-white dark:hover:text-[#635fc7] cursor-pointer "
      >
        <p
          className=" font-bold tracking-wide "
          title={subTaskInfo?.description}
        >
          <MDBRow>
            <MDBCol size="6" className=" truncate max-w-[200px]">
              {subTaskInfo?.name}
            </MDBCol>

            <MDBCol size="4">
              <div
                style={{ cursor: "pointer", alignItems: "flex-end" }}
                onClick={() => setIsModalOpen(true)}
                title={subTaskInfo?.statusName}
              >
                <MDBBadge
                  pill
                  color="secondary"
                  light
                  className=" truncate max-w-[86px]"
                >
                  {subTaskInfo?.statusName}
                </MDBBadge>
              </div>
            </MDBCol>
            <MDBCol size="2">{renderCtrDelete()}</MDBCol>
          </MDBRow>
        </p>
        <div style={{ border: "1px solid gray" }} />
        <CurrentLogTimeStatus
          statusName={subTaskInfo?.statusName}
          estimateHours={subTaskInfo?.estimateHours}
          normalHours={subTaskInfo?.normalHours ?? 0}
          overTime={subTaskInfo?.overTimeHours ?? 0}
          startDate={subTaskInfo?.startDate}
          endDate={subTaskInfo?.endDate}
        />
        <div style={{ border: "1px solid gray" }} />
        {renderLogWork()}
      </div>
    );
  };

  return (
    <div>
      {isConfirmDelete && subTaskInfo?.id && (
        <ConfirmDeleteDialog
          openModal={true}
          titleDelete={`Delete subtask ${subTaskInfo?.name}`}
          onCloseModal={() => setIsConfirmDelete(false)}
          onDelete={() => {
            handleDeleteSubTask(subTaskInfo?.id);
            setIsConfirmDelete(false);
          }}
        />
      )}
      {renderMainItem()}

      {isLogTimeOpen && (
        <LogWork
          taskInfo={subTaskInfo}
          subTasks={[subTaskInfo]}
          onCloseModal={() => setIsLogTimeOpen(false)}
          saveData={(data) => saveData(data)}
        />
      )}

      {isOpenSubTaskModal && (
        <CreateNewSubTask
          taskId={cellInfo?.id}
          teamId={userTeamId}
          taskName={cellInfo?.name}
          staffs={staffsOfPhase ?? []}
          onCloseModal={() => setIsOpenSubTaskModal(false)}
          onSave={(data) => onSaveSubTask(data)}
        />
      )}
    </div>
  );
}

export default CellSubTaskSection;
