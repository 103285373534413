import moment from "moment";

export const isNullOrUndefine = (value) =>
  value === null || value === undefined;

export const toCommas = (value) => {
  if (isNullOrUndefine(value)) return "";

  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "VND",
  }).format(value);
};

export const toThousands = (value) => {
  if (isNullOrUndefine(value)) return "";

  let number = parseFloat(value) / 1000;
  return new Intl.NumberFormat("de-DE").format(number);
};

export const onGridReady = (params) => {
  params.api.sizeColumnsToFit();
};

export const dateFormatDMY = (val) => {
  if (isNullOrUndefine(val)) return "";
  return val && moment(val).format("DD/MMM/YYYY");
};