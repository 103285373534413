import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyBoard from "../../components/EmptyBoard";
import boardsSlice from "../../redux/boardsSlice";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import ProjectHeader from "./ProjectHeader";
import MainBoard from "./MainBoard";
import Loading from "../../components/Loading";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhaseCheckList from "./PhaseCheckList";

function Projects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [project, setProject] = useState();
  const [phase, setPhase] = useState();
  const [isProject, setIsProject] = useState(true);
  const [phaseInfo, setPhaseInfo] = useState();
  const [taskInfo, setTaskInfo] = useState();
  const [taskInfoId, setTaskInfoId] = useState();
  const [showCheckList, setShowCheckList] = useState(false);

  const [projectInfo, setProjectInfo] = useState();
  const [staffsOfPhase, setStaffsOfPhase] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [projectMenus, setProjectMenus] = useState();
  const [teams, setTeams] = useState();
  const [phaseId, setPhaseId] = useState();
  const [phaseName, setPhaseName] = useState();
  const [projectId, setProjectId] = useState();
  const [statusOfPhase, setStatusOfPhase] = useState();
  const [statusOfTask, setStatusOfTask] = useState();

  const [columnInfo, setColumnInfo] = useState();
  const [checkListOfPhase, setCheckListOfPhase] = useState();
  const boards = useSelector((state) => state.boards);
  const activeBoard = boards.find((board) => board.isActive);
  if (!activeBoard && boards.length > 0)
    dispatch(boardsSlice.actions.setBoardActive({ index: 0 }));

  const [statuses, setStatuses] = useState();
  const [userRole, setUserRole] = useState();
  const [myTeamId, setMyTeamId] = useState();
  const [phaseShortInfo, setPhaseShortInfo] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Multiple projects";

    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
    setMyTeamId(infoObj.teamId);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    reloadAllListOfTeams();
    reloadAllListOfStatus();
    reloadAllListOfMenus();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    reloadAllCheckListMyTeam(myTeamId, phaseId);
  }, [phaseId, myTeamId]);

  const reloadAllCheckListMyTeam = (myTeamId, phaseId) => {
    myTeamId &&
      phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/Teams/${myTeamId}/CheckLists/${phaseId}`,
          configHeader()
        )
        .then((result) => {
          setCheckListOfPhase(result?.data);
        });
  };

  const reloadAllListOfStatus = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/All-Status`,
        configHeader()
      )
      .then((result) => {
        setStatuses(result?.data ?? []);
      });
  };

  const reloadAllListOfTeams = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/All-Teams`,
        configHeader()
      )
      .then((result) => {
        setTeams(result?.data ?? []);
      });
  };

  const reloadAllListOfMenus = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.matterUrl}/Menu-Projects`,
        configHeader()
      )
      .then((result) => {
        setProjectMenus(result?.data ?? []);
      });
  };

  useEffect(() => {
    reloadPhaseInfo(phaseId);
    reloadStaffsOfPhase(phaseId);
  }, [phaseId]);

  useEffect(() => {
    reloadProjectInfo(projectId);
  }, [projectId]);

  const reloadProjectInfo = (projectId) => {
    projectId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/${apiEndpoint.projectUrl}/${projectId}`,
          configHeader()
        )
        .then((result) => {
          setIsProject(true);
          setProjectInfo(result?.data);
        });
  };

  const reloadPhaseInfo = (phaseId) => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/${phaseId}`,
          configHeader()
        )
        .then((result) => {
          setIsProject(false);
          setPhaseInfo(result?.data);
        });
  };

  useEffect(() => {
    setTaskInfo({});
    reloadTaskInfo(taskInfoId);
  }, [taskInfoId]);

  const reloadTaskInfo = (taskInfoId) => {
    taskInfoId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/${taskInfoId}/ViewBoard`,
          configHeader()
        )
        .then((result) => {
          setTaskInfo(result?.data);
        });
  };

  const reloadStaffsOfPhase = (phaseId) => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/${phaseId}/AllStaffs`,
          configHeader()
        )
        .then((result) => {
          setStaffsOfPhase(result?.data);
        });
  };

  const changeStatus = (curTaskId, curStatus) => {
    let urlChangeStt = apiEndpoint.taskUrl;
    if (isProject) urlChangeStt = apiEndpoint.phaseUrl;
    if (taskInfoId > 0) urlChangeStt = `${apiEndpoint.taskUrl}/Subtasks`;

    axios
      .put(
        `${apiEndpoint.hosting}/api/${urlChangeStt}/${curTaskId}/Status`,
        { status: curStatus },
        configHeader()
      )
      .then(() => {
        if (taskInfoId > 0) {
          setTaskInfo({});
          reloadTaskInfo(taskInfoId);
        } else if (isProject) reloadProjectInfo(projectId);
        else reloadPhaseInfo(phaseId);

        toast.success("Status was changed successful");
      });
  };

  useEffect(() => {
    if (taskInfoId > 0) {
      setStatusOfTask(taskInfo?.statuses);
      setColumnInfo(taskInfo?.columns ?? []);
    } else if (isProject) setColumnInfo(projectInfo?.columns ?? []);
    else setColumnInfo(phaseInfo?.columns ?? []);
  }, [taskInfoId, taskInfo, isProject, projectInfo, phaseInfo]);

  const handleChangeProject = (pro) => {
    setPhase({});
    setPhaseName("");
    setPhaseId(0);
    setTaskInfoId(0);
    setTaskInfo({});
    setProject(pro);
    setProjectId(pro?.id);
    setIsProject(true);
  };

  const handleChangePhase = (phase) => {
    setPhase(phase);
    setPhaseName(phase?.name);
    setPhaseId(phase?.id);
    setTaskInfoId(0);
    setTaskInfo({});
    setIsProject(false);
  };

  const handleSelectTaskInfo = (info) => {
    setTaskInfoId(info?.id);
    setPhaseId(0);
    setProjectId(0);
    setIsProject(false);
  };

  const handleSavePhase = (data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        reloadAllListOfMenus();
        reloadProjectInfo(projectId);
        toast.success("Your phase has been created.");
      });
  };

  const onClonePhase = (phaseId, data) => {
    phaseId &&
      axios
        .post(
          `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/${phaseId}/Clone`,
          data,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          toast.success("Your phase has been cloned successfully.");
        });
  };

  const handleDelete = (phaseId) => {
    phaseId &&
      axios
        .delete(
          `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/${phaseId}`,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          toast.success("Your phase has been deleted.");
        });
  };

  const handleDeleteSubTask = (subTaskId) => {
    subTaskId &&
      axios
        .delete(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/Subtasks/${subTaskId}`,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          reloadTaskInfo(taskInfoId);
          toast.success("Your subtask has been deleted.");
        });
  };

  const handleDeleteTask = (taskInfoId) => {
    taskInfoId &&
      axios
        .delete(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/${taskInfoId}`,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          reloadPhaseInfo(phaseId);
          toast.success("Your task has been deleted.");
        });
  };

  const handleSaveBasePhase = (id, data) => {
    setIsLoading(true);
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/${id}/BasicInfo`,
          data,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          setIsLoading(false);
          toast.success("Your phase has been updated.");
        });
  };

  const handleSaveTask = (data) => {
    setIsLoading(true);
    axios
      .post(
        `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        try {
          reloadPhaseInfo(phaseId);
          reloadAllListOfMenus();
          setIsLoading(false);
        } catch (err) {
          toast.error("Something went wrong");
        }
        toast.success("Your task has been created.");
      });
  };

  const onUpdateTask = (data) => {
    axios
      .put(
        `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        reloadAllListOfMenus();
        reloadPhaseInfo(phaseId);
        toast.success("Your task has been updated.");
      });
  };

  const handleLogWork = (taskId) => {
    reloadAllListOfMenus();
    setTaskInfo({});
    reloadTaskInfo(taskId);
  }

  const handleAddSubtask = (taskId, data) => {
    taskId &&
      axios
        .post(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/${taskId}/Subtask`,
          data,
          configHeader()
        )
        .then(() => {
          reloadPhaseInfo(phaseId);
          reloadTaskInfo(taskId);
        });
  };

  const handleUpdateSubtask = (subTaskId, data) => {
    subTaskId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/Subtasks/${subTaskId}`,
          data,
          configHeader()
        )
        .then(() => {
          reloadPhaseInfo(phaseId);
        });
  };

  const handleShowCheckListOfPhase = (data) => {
    setShowCheckList(true);
    setPhaseShortInfo(data);
  };

  const onSaveProject = (data) => {
    setIsLoading(true);
    axios
      .post(
        `${apiEndpoint.hosting}/api/${apiEndpoint.projectUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        toast.success("Your project has been added successful.");
        reloadAllListOfMenus();
        reloadProjectInfo(projectId);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/${apiEndpoint.phaseUrl}/${phaseId}/AllStatus`,
          configHeader()
        )
        .then((result) => {
          setStatusOfPhase(result?.data);
        });
  }, [phaseId]);

  const handleSaveCheckList = (phaseId, data, targetChecked) => {
    if (targetChecked) activeCheckList(phaseId, data);
    else disableCheckList(phaseId, data);
  };

  const disableCheckList = (phaseId, data) => {
    phaseId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/Teams/${phaseId}/CheckList/Disable`,
          data,
          configHeader()
        )
        .then(() => { })
        .catch(() => {
          toast.error(
            "Something went wrong, Check list is not yet disable, please try again later"
          );
        });
  };

  const activeCheckList = (phaseId, data) => {
    phaseId &&
      axios
        .put(
          `${apiEndpoint.hosting}/api/Teams/${phaseId}/CheckList/Active`,
          data,
          configHeader()
        )
        .then(() => { })
        .catch(() => {
          toast.error(
            "Something went wrong, Check list is not active, please try again later"
          );
        });
  };

  return (
    <div className=" overflow-hidden overflow-x-scroll" style={{ flex: "1" }}>
      <>
        {isLoading && <Loading />}
        {showCheckList && checkListOfPhase?.length > 0 && (
          <PhaseCheckList
            open={showCheckList}
            phaseInfo={phaseShortInfo}
            teamId={myTeamId}
            checkListOfPhase={checkListOfPhase}
            handleOnClose={() => setShowCheckList(false)}
            saveData={(phaseId, data, targetChecked) =>
              handleSaveCheckList(phaseId, data, targetChecked)
            }
          />
        )}
        {(boards ?? []).length > 0 ? (
          <>
            <ProjectHeader
              setIsBoardModalOpen={setIsBoardModalOpen}
              isBoardModalOpen={isBoardModalOpen}
              project={project}
              phaseName={isProject ? "" : phaseName}
              phaseId={phaseId}
              phase={phase}
              statuses={statuses ?? []}
              teams={teams ?? []}
              staffsOfPhase={staffsOfPhase ?? []}
              userRole={userRole}
              statusOfPhase={statusOfPhase}
              taskInfo={taskInfo}
              onSavePhase={(data) => handleSavePhase(data)}
              onSaveTask={(data) => handleSaveTask(data)}
              handleAddSubtask={(id, data) => handleAddSubtask(id, data)}
              reloadPhaseInfo={reloadPhaseInfo}
              reloadTaskInfo={reloadTaskInfo}
            />

            <MainBoard
              setIsBoardModalOpen={setIsBoardModalOpen}
              isBoardModalOpen={isBoardModalOpen}
              columns={columnInfo}
              projects={projectMenus ?? []}
              statuses={statuses ?? []}
              projectId={projectId}
              currentPhaseId={phaseId}
              statusOfPhase={statusOfPhase}
              userRole={userRole}
              isProject={isProject}
              teams={teams ?? []}
              staffsOfPhase={staffsOfPhase ?? []}
              onSelectProject={(pro) => handleChangeProject(pro)}
              onSelectPhase={(phase) => handleChangePhase(phase)}
              onSelectTask={(item) => handleSelectTaskInfo(item)}
              changeStatus={changeStatus}
              onSavePhase={(id, data) => handleSaveBasePhase(id, data)}
              handleAddSubtask={(id, data) => handleAddSubtask(id, data)}
              handleUpdateSubtask={(id, data) => handleUpdateSubtask(id, data)}
              onSaveProject={(data) => onSaveProject(data)}
              onSaveTask={(data) => handleSaveTask(data)}
              handleDelete={handleDelete}
              onUpdateTask={(data) => onUpdateTask(data)}
              handleDeleteTask={handleDeleteTask}
              onClonePhase={onClonePhase}
              statusOfTask={statusOfTask}
              taskInfoId={taskInfoId}
              taskInfo={taskInfo}
              handleDeleteSubTask={handleDeleteSubTask}
              reloadPhaseInfo={reloadPhaseInfo}
              onShowCheckListOfPhase={(phase) =>
                handleShowCheckListOfPhase(phase)
              }
              handleLogWork={handleLogWork}
            />
          </>
        ) : (
          <>
            <EmptyBoard type="add" />
          </>
        )}
      </>
      <ToastContainer />
    </div>
  );
}

export default Projects;
