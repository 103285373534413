import React, { useEffect, useState } from "react";

import {
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavbar,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
} from "mdb-react-ui-kit";
import Avatar from "./Avatar";
import { useNavigate } from "react-router-dom";
function Menu({ hasAvatar }) {
  const navigate = useNavigate();
  const [userMenu, setUserMenu] = useState();

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    let menus = defineUserMenu(infoObj.role);
    setUserMenu(menus);
  }, []);

  const defineUserMenu = (role) => {
    if (role === "Admin") {
      return (
        <>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/projects")}>
              <MDBIcon fas icon="city" style={{ marginRight: 15 }} />
              Project Board
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBDropdown style={{ backgroundColor: "white" }}>
              <MDBDropdownToggle tag="a" className="nav-link" role="button">
                <MDBIcon fas icon="th" style={{ marginRight: 15 }} />
                Settings
              </MDBDropdownToggle>
              <MDBDropdownMenu light>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/members")}>
                  <MDBNavbarLink onClick={() => navigate("/members")}>
                    <MDBIcon
                      fas
                      icon="user-friends"
                      style={{ marginRight: 15 }}
                    />
                    List of Users
                  </MDBNavbarLink>
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/teams")}>
                  <MDBNavbarLink onClick={() => navigate("/teams")}>
                    <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
                    Team members
                  </MDBNavbarLink>
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/my-projects")}>
                  <MDBNavbarLink onClick={() => navigate("/my-projects")}>
                    <MDBIcon
                      fas
                      icon="grip-horizontal"
                      style={{ marginRight: 15 }}
                    />
                    List of Projects
                  </MDBNavbarLink>
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/check-list")}>
                  <MDBNavbarLink onClick={() => navigate("/check-list")}>
                    <MDBIcon fas icon="th-list" style={{ marginRight: 15 }} />
                    Checklist
                  </MDBNavbarLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>

          <MDBNavbarItem light>
            <MDBDropdown style={{ backgroundColor: "white" }}>
              <MDBDropdownToggle tag="a" className="nav-link" role="button">
                <MDBIcon fas icon="desktop" style={{ marginRight: 15 }} />
                Project Planning
              </MDBDropdownToggle>
              <MDBDropdownMenu light>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/planning")}>
                  <MDBNavbarLink onClick={() => navigate("/planning")}>
                    <MDBIcon
                      fas
                      icon="file-invoice-dollar"
                      style={{ marginRight: 15 }}
                    />
                    Project Planning
                  </MDBNavbarLink>
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/assign-projects")}>
                  <MDBNavbarLink onClick={() => navigate("/assign-projects")}>
                    <MDBIcon
                      fas
                      icon="user-ninja"
                      style={{ marginRight: 15 }}
                    />
                    Assign to PM
                  </MDBNavbarLink>
                </MDBDropdownItem>

                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/time-sheets")}>
                  <MDBNavbarLink onClick={() => navigate("/time-sheets")}>
                    <MDBIcon
                      fas
                      icon="calendar-times"
                      style={{ marginRight: 15 }}
                    />
                    View Time sheet
                  </MDBNavbarLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>

          <MDBNavbarItem>

            <MDBDropdown style={{ backgroundColor: "white" }}>
              <MDBDropdownToggle tag="a" className="nav-link" role="button">
                <MDBIcon fas icon="money-bill-alt" style={{ marginRight: 15 }} />
                Bills
              </MDBDropdownToggle>
              <MDBDropdownMenu light>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/companies")}>
                  <MDBNavbarLink onClick={() => navigate("/companies")}>
                    <MDBIcon
                      fas
                      icon="building"
                      style={{ marginRight: 15 }}
                    />
                    List of Customers
                  </MDBNavbarLink>
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/budgets")}>
                  <MDBNavbarLink onClick={() => navigate("/budgets")}>
                    <MDBIcon
                      fas
                      icon="money-check-alt"
                      style={{ marginRight: 15 }}
                    />
                    List of Budgets
                  </MDBNavbarLink>
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/bills")}>
                  <MDBNavbarLink onClick={() => navigate("/bills")}>
                    <MDBIcon
                      fas
                      icon="file-invoice"
                      style={{ marginRight: 15 }}
                    />
                    List of Bills
                  </MDBNavbarLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
        </>
      );
    }

    if (role === "Manager") {
      return (
        <>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/projects")}>
              <MDBIcon fas icon="city" style={{ marginRight: 15 }} />
              Project Board
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/my-projects")}>
              <MDBIcon fas icon="grip-horizontal" style={{ marginRight: 15 }} />
              Projects
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/time-sheets")}>
              <MDBIcon fas icon="calendar-times" style={{ marginRight: 15 }} />
              View Time sheet
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/resources")}>
              <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
              Monitor resources
            </MDBNavbarLink>
          </MDBNavbarItem>
        </>
      );
    }

    if (role === "Teamleader") {
      return (
        <>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/projects")}>
              <MDBIcon fas icon="city" style={{ marginRight: 15 }} />
              Project Board
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/time-sheets")}>
              <MDBIcon fas icon="calendar-times" style={{ marginRight: 15 }} />
              View Time sheet
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/members")}>
              <MDBIcon fas icon="user-friends" style={{ marginRight: 15 }} />
              List of Users
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/check-list")}>
              <MDBIcon fas icon="th-list" style={{ marginRight: 15 }} />
              Checklist Template
            </MDBNavbarLink>
          </MDBNavbarItem>
        </>
      );
    }

    if (role === "Accountant") {
      return (
        <>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/projects")}>
              <MDBIcon fas icon="city" style={{ marginRight: 15 }} />
              Projects
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink onClick={() => navigate("/bills")}>
              <MDBIcon fas icon="money-bill-alt" style={{ marginRight: 15 }} />
              Bills
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBDropdown style={{ backgroundColor: "white" }}>
              <MDBDropdownToggle tag="a" className="nav-link" role="button">
                <MDBIcon fas icon="th" style={{ marginRight: 15 }} />
                Settings
              </MDBDropdownToggle>
              <MDBDropdownMenu light>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/companies")}>
                  <MDBNavbarLink onClick={() => navigate("/companies")}>
                    <MDBIcon
                      fas
                      icon="building"
                      style={{ marginRight: 15 }}
                    />
                    List of Customers
                  </MDBNavbarLink>
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/budgets")}>
                  <MDBNavbarLink onClick={() => navigate("/budgets")}>
                    <MDBIcon
                      fas
                      icon="money-check-alt"
                      style={{ marginRight: 15 }}
                    />
                    List of Budgets
                  </MDBNavbarLink>
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={() => navigate("/receipt-settings")}>
                  <MDBNavbarLink onClick={() => navigate("/receipt-settings")}>
                    <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
                    Receipt Settings
                  </MDBNavbarLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
        </>
      );
    }

    return (
      <>
        <MDBNavbarItem>
          <MDBNavbarLink onClick={() => navigate("/projects")}>
            <MDBIcon fas icon="city" style={{ marginRight: 15 }} />
            Project Board
          </MDBNavbarLink>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <MDBNavbarLink onClick={() => navigate("/time-sheets")}>
            <MDBIcon fas icon="grip-horizontal" style={{ marginRight: 15 }} />
            View Time sheet
          </MDBNavbarLink>
        </MDBNavbarItem>
      </>
    );
  };

  return (
    <>
      <MDBNavbar expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarNav right className="mb-2 mb-lg-0">
            <MDBNavbarItem active>
              <MDBNavbarLink
                aria-current="page"
                onClick={() => navigate("/home")}
              >
                <MDBIcon fas icon="house-damage" style={{ marginRight: 15 }} />
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink onClick={() => navigate("/dashboard")}>
                <MDBIcon fas icon="chart-line" style={{ marginRight: 15 }} />
                Dashboard
              </MDBNavbarLink>
            </MDBNavbarItem>
            {userMenu}
          </MDBNavbarNav>
        </MDBContainer>
        {hasAvatar && <Avatar />}
      </MDBNavbar>
    </>
  );
}

export default Menu;
