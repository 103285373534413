import { useEffect, useState } from "react";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import axios from "axios";
import { configHeader } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import CurrentLogTimeStatus from "./CurrentLogTimeStatus";
import CreateNewSubTask from "../../modals/CreateNewSubTask";
import ViewTeamMembers from "../../modals/ViewTeamMembers";
import ListSubTasks from "./ListSubTasks";
import LogWork from "./LogWork";
import UpdateTaskInfo from "../../modals/UpdateTaskInfo";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";

function CellSection({
  colIndex,
  taskIndex,
  cellInfo,
  teams,
  userRole,
  staffsOfPhase,
  onUpdateTask,
  handleDeleteTask,
  reloadPhaseInfo,
  currentPhaseId,
  handleLogWork
}) {
  const taskInfo = cellInfo ?? {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogTimeOpen, setIsLogTimeOpen] = useState(false);
  const [taskDetailInfo, setTaskDetailInfo] = useState();
  const [isOpenTeamMembers, setIsOpenTeamMembers] = useState(false);
  const [isOpenSubTaskModal, setIsOpenSubTaskModal] = useState(false);
  const [teamId, setTeamId] = useState();
  const [teamName, setTeamName] = useState();
  const [userTeamId, setUserTeamId] = useState();
  const [canModify, setCanModify] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [taskInfoId, setTaskInfoId] = useState(taskInfo.id);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserTeamId(infoObj.teamId);
    if (userRole === "Admin" || userRole === "Manager") {
      setCanModify(true);
    }

    setTaskInfoId(taskInfo?.id);
  }, []);

  useEffect(() => {
    reloadTaskDetailInfo(taskInfoId);
  }, [taskInfoId]);

  const reloadTaskDetailInfo = (taskInfoId) => {
    taskInfoId &&
      axios
        .get(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/${taskInfoId}`,
          configHeader()
        )
        .then((result) => {
          setTaskDetailInfo(result?.data);
        });
  };

  const saveData = (data) => {

    taskInfo?.id &&
      axios
        .post(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/${taskInfo.id}/LogWork`,
          data,
          configHeader()
        )
        .then(() => {
          handleLogWork(taskInfo?.id, data);
          reloadTaskDetailInfo();
          setIsLogTimeOpen(false);
          setIsModalOpen(false);
        });
  };

  const handleOnDrag = (e) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ taskIndex, prevColIndex: colIndex })
    );
  };

  const handleOpenSubTask = (teamId) => {
    setIsOpenSubTaskModal(true);
    setTeamId(teamId);
  };

  const handleOpenUsersOfSubTask = (teamId, teamName) => {
    setIsOpenTeamMembers(true);
    setTeamId(teamId);
    setTeamName(teamName);
  };

  const onSaveSubTask = (data) => {
    taskInfoId &&
      axios
        .post(
          `${apiEndpoint.hosting}/api/${apiEndpoint.taskUrl}/${taskInfoId}/Subtask`,
          data,
          configHeader()
        )
        .then(() => {
          reloadTaskDetailInfo(taskInfoId);
          setIsOpenSubTaskModal(false);
          reloadPhaseInfo(currentPhaseId);
        });
  };

  const renderCtrDelete = () => {
    if (userRole === "Admin" || userRole === "Manager") {
      return (
        <MDBRow>
          <MDBCol size="3" sm="12" title="Delete this Task">
            <div
              style={{ cursor: "pointer", alignItems: "flex-end" }}
              onClick={() => {
                setIsConfirmDelete(true);
              }}
            >
              <MDBIcon fas icon="trash-alt" color="warning" />
            </div>
          </MDBCol>
        </MDBRow>
      );
    }

    return <></>;
  };

  const renderLogWork = () => {
    let hasTeam =
      (taskDetailInfo?.teamIds ?? []).filter((item) => +userTeamId === +item)
        ?.length > 0;

    if (!hasTeam && userRole !== "Admin" && userRole !== "Manager")
      return <></>;

    return (
      <div style={{ cursor: "pointer" }} onClick={() => setIsLogTimeOpen(true)}>
        <div style={{ border: "1px solid gray" }} />
        Log Working
      </div>
    );
  };

  const renderAddSubTaskByTeam = (itemId, itemName) => {
    if (
      userRole !== "Admin" &&
      userRole !== "Manager" &&
      +userTeamId !== +itemId
    )
      return <></>;

    return (
      <>
        <MDBCol size="3" sm="2" title={"View current sub tasks"}>
          <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => handleOpenUsersOfSubTask(itemId, itemName)}
          >
            <MDBIcon fas icon="eye" />
          </div>
        </MDBCol>
        <MDBCol size="3" sm="2" title={"Add new sub task"}>
          <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => handleOpenSubTask(itemId)}
          >
            <MDBIcon fas icon="plus" />
          </div>
        </MDBCol>
      </>
    );
  };

  const renderMainItem = () => {
    return (
      <div
        draggable
        onDragStart={handleOnDrag}
        className=" w-[280px] first:my-5 rounded-md  bg-white  dark:bg-[#2b2c37] shadow-[#364e7e1a] py-6 px-3 shadow-lg hover:text-[#635fc7] dark:text-white dark:hover:text-[#635fc7] cursor-pointer "
      >
        <p className=" font-bold tracking-wide " title={cellInfo?.description}>
          <MDBRow>
            <MDBCol size="8" className=" truncate max-w-[200px]">
              <MDBIcon fas icon="tasks" style={{ marginRight: 15 }} /> {cellInfo?.name}
            </MDBCol>
            <MDBCol size="2">
              <div
                style={{ cursor: "pointer", alignItems: "flex-end" }}
                onClick={() => setIsModalOpen(true)}
                title={`Update task information: ${cellInfo?.name}`}
              >
                <MDBIcon fas icon="user-edit" />
              </div>
            </MDBCol>
            <MDBCol size="2">{renderCtrDelete()}</MDBCol>
          </MDBRow>
        </p>
        <div style={{ border: "1px solid gray" }} />
        <CurrentLogTimeStatus
          statusName={taskDetailInfo?.statusName}
          estimateHours={taskDetailInfo?.estimateHours}
          normalHours={taskDetailInfo?.normalHours ?? 0}
          overTime={taskDetailInfo?.overTimeHours ?? 0}
          startDate={taskDetailInfo?.startDate}
          endDate={taskDetailInfo?.endDate}
        />
        <div style={{ border: "1px solid gray" }} />
        {(taskDetailInfo?.teamIds ?? []).length > 0 && "Teams"}
        {(teams ?? []).map((item) => {
          let isChecked =
            (taskDetailInfo?.teamIds ?? []).filter((it) => it === item.id)
              ?.length > 0;

          return !isChecked ? (
            <></>
          ) : (
            <MDBRow>
              <MDBCol size="3" sm="1"></MDBCol>
              <MDBCol size="3" sm="6" className=" truncate max-w-[240px]">
                {item?.name}
              </MDBCol>
              {renderAddSubTaskByTeam(item?.id, item?.name)}
            </MDBRow>
          );
        })}
        <ListSubTasks subTasks={taskDetailInfo?.subTasks ?? []} />
        {renderLogWork()}
      </div>
    );
  };

  return (
    <div>
      {isConfirmDelete && taskInfo?.id && (
        <ConfirmDeleteDialog
          openModal={true}
          titleDelete={`Delete task ${taskInfo?.name}`}
          onCloseModal={() => setIsConfirmDelete(false)}
          onDelete={() => {
            handleDeleteTask(taskInfo?.id);
            setIsConfirmDelete(false);
          }}
        />
      )}
      {renderMainItem()}
      {isModalOpen && canModify && (
        <UpdateTaskInfo
          teams={(teams ?? []).filter((item) => item.isActive)}
          userRole={userRole}
          taskDetailInfo={taskDetailInfo}
          taskInfo={taskInfo}
          onCloseModal={() => setIsModalOpen(false)}
          onSave={(data) => onUpdateTask(data)}
        />
      )}

      {isLogTimeOpen && (
        <LogWork
          taskInfo={taskInfo}
          subTasks={taskDetailInfo?.subTasks ?? []}
          onCloseModal={() => setIsLogTimeOpen(false)}
          saveData={(data) => saveData(data)}
        />
      )}
      {isOpenTeamMembers && (
        <ViewTeamMembers
          taskId={cellInfo?.id}
          teamId={teamId}
          teamName={teamName}
          taskName={cellInfo?.name}
          taskDetailInfo={taskDetailInfo}
          onCloseModal={() => setIsOpenTeamMembers(false)}
        />
      )}
      {isOpenSubTaskModal && (
        <CreateNewSubTask
          taskId={cellInfo?.id}
          teamId={teamId}
          taskName={cellInfo?.name}
          staffs={staffsOfPhase ?? []}
          onCloseModal={() => setIsOpenSubTaskModal(false)}
          onSave={(data) => onSaveSubTask(data)}
        />
      )}
    </div>
  );
}

export default CellSection;
