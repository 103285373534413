import { shuffle } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CellSection from "./CellSection";
import CellPhaseSection from "./CellPhaseSection";
import CellSubTaskSection from "./CellSubTaskSection";

function ColumnSection({
  columnInfo,
  colIndex,
  statusOfPhase,
  isProject,
  taskInfoId,
  teams,
  userRole,
  staffsOfPhase,
  dragTask,
  onSavePhase,
  handleAddSubtask,
  handleUpdateSubtask,
  onSaveTask,
  handleDelete,
  onUpdateTask,
  handleDeleteTask,
  onClonePhase,
  handleDeleteSubTask,
  currentPhaseId,
  reloadPhaseInfo,
  handleLogWork
}) {
  const colors = [
    "bg-red-500",
    "bg-orange-500",
    "bg-blue-500",
    "bg-purple-500",
    "bg-green-500",
    "bg-indigo-500",
    "bg-yellow-500",
    "bg-pink-500",
    "bg-sky-500",
  ];

  const dispatch = useDispatch();
  const [color, setColor] = useState(null);
  const [entities, setEntities] = useState(columnInfo?.entities ?? []);

  useEffect(() => {
    setEntities(columnInfo?.entities ?? []);
  }, [columnInfo]);

  useEffect(() => {
    setColor(shuffle(colors).pop());
  }, [dispatch]);

  const handleOnDrop = (e) => {
    const { prevColIndex, taskIndex } = JSON.parse(
      e.dataTransfer.getData("text")
    );

    if (colIndex !== prevColIndex) {
      dragTask(colIndex, prevColIndex, taskIndex);
    }
  };

  const handleOnDragOver = (e) => {
    e.preventDefault();
  };

  const renderTitle = () => {
    if ((entities ?? []).length < 1) {
      return (
        <p className=" font-semibold flex  items-center  gap-2 tracking-widest md:tracking-[.2em] text-[#828fa3]">
          <div className={`rounded-full w-4 h-4 ${color} `} />
          {columnInfo?.status}
        </p>
      );

    }

    return (
      <p className=" font-semibold flex  items-center  gap-2 tracking-widest md:tracking-[.2em] text-[#828fa3]">
        <div className={`rounded-full w-4 h-4 ${color} `} />
        {columnInfo?.status} ({entities?.length})
      </p>
    );
  };

  const renderCellSectionInfo = (entity, index) => {
    if (taskInfoId > 0) {
      return (
        <CellSubTaskSection
          key={index}
          taskIndex={index}
          colIndex={colIndex}
          cellInfo={entity}
          statusOfPhase={statusOfPhase}
          teams={teams}
          userRole={userRole}
          staffsOfPhase={entity?.staffs ?? staffsOfPhase}
          handleAddSubtask={handleAddSubtask}
          handleUpdateSubtask={handleUpdateSubtask}
          onUpdateTask={onUpdateTask}
          handleDeleteSubTask={handleDeleteSubTask}
          reloadPhaseInfo={reloadPhaseInfo}
          currentPhaseId={currentPhaseId}
          taskInfoId={taskInfoId}
        />
      );
    }

    if (isProject)
      return (
        <CellPhaseSection
          key={index}
          taskIndex={index}
          colIndex={colIndex}
          cellInfo={entity}
          onSave={onSavePhase}
          userRole={userRole}
          statusOfPhase={statusOfPhase}
          teams={teams}
          onSaveTask={onSaveTask}
          handleDelete={handleDelete}
          onClonePhase={onClonePhase}
        />
      );

    return (
      <CellSection
        key={index}
        taskIndex={index}
        colIndex={colIndex}
        cellInfo={entity}
        statusOfPhase={statusOfPhase}
        teams={teams}
        userRole={userRole}
        staffsOfPhase={staffsOfPhase}
        handleAddSubtask={handleAddSubtask}
        handleUpdateSubtask={handleUpdateSubtask}
        onUpdateTask={onUpdateTask}
        handleDeleteTask={handleDeleteTask}
        reloadPhaseInfo={reloadPhaseInfo}
        currentPhaseId={currentPhaseId}
        handleLogWork={handleLogWork}
      />
    );
  };

  return (
    <div
      onDrop={handleOnDrop}
      onDragOver={handleOnDragOver}
      className="scrollbar-hide mx-5 pt-[120px] min-w-[280px] "
    >
      {renderTitle()}
      {(entities ?? []).map((entity, index) => {
        return renderCellSectionInfo(entity, index);
      })}
    </div>
  );
}

export default ColumnSection;
