import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Login from "../Login";
import Projects from "../Projects";
import Dashboard from "../Dashboard";
import Grids from "../Grids";
import LandingPage from "./LandingPage";
import Planning from "../Planning";
import Bills from "../Bills";
import ViewSubTask from "../Grids/ViewSubTask";
import ResourcePlan from "../Planning/ResourcePlan";
import UserSettings from "../Users/UserSettings";
import UserNotifications from "../Users/UserNotifications";
import UserLanguage from "../Users/UserLanguage";
import UserInfo from "../Users/UserInfo";
import TeamMembers from "../Teams/TeamMembers";
import AssignProject from "../Planning/AssignProject";
import UserMembers from "../Users/Members";
import CheckList from "../CheckList";
import Company from "../Company";
import ReceiptSettings from "../Users/ReceiptSettings";
import PhaseBudget from "../Bills/PhaseBudget";

function App() {
  document.title = "spaceeng.info.vn";
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="/home" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-projects" element={<Grids />} />
          <Route path="/planning" element={<Planning />} />
          <Route path="/bills" element={<Bills />} />
          <Route path="/time-sheets" element={<ViewSubTask />} />
          <Route path="/resources" element={<ResourcePlan />} />
          <Route path="/users/settings" element={<UserSettings />} />
          <Route path="/users/notifications" element={<UserNotifications />} />
          <Route path="/users/language" element={<UserLanguage />} />
          <Route path="/users/:id" element={<UserInfo />} />
          <Route path="/teams" element={<TeamMembers />} />
          <Route path="/members" element={<UserMembers />} />
          <Route path="/assign-projects" element={<AssignProject />} />
          <Route path="/check-list" element={<CheckList />} />
          <Route path="/companies" element={<Company />} />
          <Route path="/receipt-settings" element={<ReceiptSettings />} />
          <Route path="/budgets" element={<PhaseBudget />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
