import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onGridReady } from "../../utils/utilities";

function UserNotifications() {
  const [notifyToUsers, setNotifyToUsers] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Notifications";

    reloadNotifyToUsers();
  }, []);

  const reloadNotifyToUsers = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.userUrl}/NotifyToUsers`,
        configHeader()
      )
      .then((result) => {
        setNotifyToUsers(result?.data ?? []);
      });
  };

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "msgNotify",
      headerName: "Notify",
      cellStyle: {
        textAlign: "left",
      },
      width: 950,
      filter: "agSetColumnFilter",
    },
  ];

  return (
    <>
      <div className="ag-theme-quartz" style={{ height: "75vh" }}>
        <AgGridReact rowData={notifyToUsers ?? []} columnDefs={colDefs} onGridReady={onGridReady} />
      </div>
    </>
  );
}

export default UserNotifications;
