import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ReceiptSettings() {
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState();
  const [cashier, setCashier] = useState();
  const [chiefAccountant, setChiefAccountant] = useState();
  const [director, setDirector] = useState();
  const [bankAccount, setBankAccount] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Receipt Settings";
    reloadMyCompany();
  }, []);

  useEffect(() => {
    setCashier(companyInfo?.cashier);
    setChiefAccountant(companyInfo?.chiefAccountant);
    setDirector(companyInfo?.director);
    setBankAccount(companyInfo?.bankAccount);
  }, [companyInfo]);

  const reloadMyCompany = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/api/${apiEndpoint.companyUrl}/my-company`,
        configHeader()
      )
      .then((result) => {
        setCompanyInfo(result?.data)

      });
  };

  const saveData = () => {
    axios
      .post(
        `${apiEndpoint.hosting}/api/${apiEndpoint.companyUrl}/my-company`,
        {
          "id": 0,
          "bankAccount": bankAccount,
          "director": director,
          "cashier": cashier,
          "chiefAccountant": chiefAccountant
        },
        configHeader()
      )
      .then(() => {
        toast.success(`Your data was changed successful.`);
      });
  }

  const renderBody = () => {
    return <>
      <MDBRow>
        <MDBCol size="3" sm="12">
          <MDBInput
            className="mb-3"
            label="Cashier"
            title="Change cashier"
            value={cashier}
            onChange={(evt) => setCashier(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="3" sm="12">
          <MDBInput
            className="mb-3"
            label="Chief Accountant"
            title="Change Chief Accountant"
            value={chiefAccountant}
            onChange={(evt) => setChiefAccountant(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="3" sm="12">
          <MDBInput
            className="mb-3"
            label="Director"
            title="Change director"
            value={director}
            onChange={(evt) => setDirector(evt.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="3" sm="12"><MDBInput
          className="mb-3"
          label="Bank Account"
          title="Change Bank Account"
          value={bankAccount}
          onChange={(evt) => setBankAccount(evt.target.value)}
        />
        </MDBCol>
      </MDBRow>
    </>
  }
  return <>
    <MDBRow style={{ textAlign: "left", marginTop: 25 }}>
      <MDBCol size="3" sm="9">
        <MDBCard>
          <MDBCardTitle>Receipt information</MDBCardTitle>
          <MDBCardBody>
            {companyInfo && renderBody()}
          </MDBCardBody>
          <MDBCardFooter>
            <MDBBtn onClick={() => saveData()}> <MDBIcon fas icon="save" /> Save</MDBBtn>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    <ToastContainer />
  </>;
}

export default ReceiptSettings;
