import { useEffect, useState } from "react";
import Menu from "../App/Menu";
import { MDBBtn } from "mdb-react-ui-kit";
import CreatePhase from "../../modals/CreatePhase";
import CreateNewTask from "../../modals/CreateNewTask";
import CreateNewSubTask from "../../modals/CreateNewSubTask";

function ProjectHeader({
  project,
  phaseName,
  phaseId,
  statuses,
  statusOfPhase,
  teams,
  taskInfo,
  staffsOfPhase,
  userRole,
  onSavePhase,
  onSaveTask,
  handleAddSubtask,
  reloadTaskInfo,
}) {
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [openPhaseModal, setOpenPhaseModal] = useState(false);
  const [openSubTaskModal, setOpenSubTaskModal] = useState(false);
  const [titleBoard, setTitleBoard] = useState([]);
  const [teamId, setTeamId] = useState();

  useEffect(() => {
    if (taskInfo?.taskName?.length > 0) {
      setTitleBoard([taskInfo?.taskName]);
    } else if (phaseName?.length < 1) {
      setTitleBoard([project?.name]);
    } else setTitleBoard([phaseName, project?.name]);
  }, [taskInfo, project, phaseName]);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setTeamId(infoObj.teamId);
  }, []);

  const handleAddTask = () => {
    setOpenTaskModal(true);
  };

  const handleAddPhase = () => {
    setOpenPhaseModal(true);
  };

  useEffect(() => {
    if (taskInfo?.taskName?.length > 0) {
      setTitleBoard([taskInfo?.taskName]);
    } else if (phaseName?.length < 1) {
      setTitleBoard([project?.name]);
    } else setTitleBoard([phaseName, project?.name]);
  }, [taskInfo?.taskName, phaseName, project?.name]);

  const renderButtons = () => {
    if (userRole !== "Admin" && userRole !== "Manager") {
      if (taskInfo?.taskId > 0) {
        return (
          <MDBBtn
            style={{ cursor: "pointer", borderRadius: 16 }}
            color={"success"}
            onClick={() => setOpenSubTaskModal(true)}
          >
            Add Sub Task
          </MDBBtn>
        );
      }

      if (phaseName?.length > 0 && (phaseId ?? 0) > 0) {
        return (
          <MDBBtn
            style={{ cursor: "pointer", borderRadius: 16 }}
            color={"secondary"}
            onClick={() => handleAddTask()}
          >
            Add Task
          </MDBBtn>
        );
      }

      return <></>;
    }
    return (
      <div className=" flex items-right space-x-2  md:space-x-4">
        {taskInfo?.taskId > 0 && (
          <MDBBtn
            style={{ cursor: "pointer", borderRadius: 16 }}
            color={"success"}
            onClick={() => setOpenSubTaskModal(true)}
          >
            Add Sub Task
          </MDBBtn>
        )}

        {phaseName?.length > 0 && (phaseId ?? 0) > 0 && (
          <MDBBtn
            style={{ cursor: "pointer", borderRadius: 16 }}
            color={"secondary"}
            onClick={() => handleAddTask()}
          >
            Add Task
          </MDBBtn>
        )}

        {(project?.name ?? "").length > 0 && project?.id > 0 && (
          <MDBBtn
            style={{
              cursor: "pointer",
              borderRadius: 16,
              display: "inline-flex",
            }}
            color={"info"}
            onClick={() => handleAddPhase()}
          >
            Add Phase
          </MDBBtn>
        )}
      </div>
    );
  };

  const onSaveSubTask = (data) => {
    handleAddSubtask(taskInfo?.taskId, data);
    setOpenSubTaskModal(false);
    reloadTaskInfo(taskInfo?.taskId);
  };

  return (
    <div className=" p-1 fixed left-0 bg-white dark:bg-[#2b2c37] right-0 ">
      <Menu hasAvatar />
      <header
        className=" flex justify-between dark:text-white items-center  "
        style={{
          marginTop: 20,
          color: "white",
          backgroundImage: "conic-gradient(yellow, #9e9ed1, black)",
        }}
      >
        {/* Left Side */}
        <div
          className=" flex items-center space-x-2  md:space-x-4"
          style={{ marginLeft: "25vw" }}
        >
          <div className=" flex items-center ">
            <>
              <h3 className=" truncate max-w-[500px] md:text-2xl text-xl font-bold md:ml-20 font-sans  ">
                {titleBoard[0]}
              </h3>
              <h5 className=" truncate max-w-[500px] md:text-2xl text-xl  md:ml-10 font-sans  ">
                {titleBoard[1]}
              </h5>
            </>
          </div>
        </div>
        {renderButtons()}
      </header>
      {openTaskModal && (phaseId ?? 0) > 0 && (
        <CreateNewTask
          phaseId={phaseId}
          phaseName={phaseName}
          statuses={statusOfPhase ?? []}
          teams={(teams ?? []).filter((item) => item.isActive)}
          userRole={userRole}
          onCloseModal={() => setOpenTaskModal(false)}
          onSave={(data) => onSaveTask(data)}
        />
      )}
      {openPhaseModal && (project?.id ?? 0) > 0 && (
        <CreatePhase
          projectId={project?.id}
          projectName={project?.name}
          statuses={statuses ?? []}
          onCloseModal={() => setOpenPhaseModal(false)}
          onSave={(data) => onSavePhase(data)}
        />
      )}
      {openSubTaskModal && (
        <CreateNewSubTask
          taskId={taskInfo?.taskId}
          teamId={teamId}
          taskName={taskInfo?.taskName}
          staffs={staffsOfPhase ?? []}
          onCloseModal={() => setOpenSubTaskModal(false)}
          onSave={(data) => onSaveSubTask(data)}
        />
      )}
    </div>
  );
}

export default ProjectHeader;
