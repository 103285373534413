import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { useState } from "react";

function ConfirmDeleteDialog({
  titleDelete,
  openModal,
  onCloseModal,
  onDelete,
}) {
  return (
    <>
      <MDBModal open={openModal} onClose={onCloseModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{titleDelete ?? "Delete"}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={onCloseModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>Are you sure delete this item ?</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={onCloseModal}>
                Close
              </MDBBtn>
              <MDBBtn onClick={onDelete} color="danger">
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default ConfirmDeleteDialog;
