import { useState } from "react";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import CurrentLogTimeStatus from "./CurrentLogTimeStatus";
import CreateNewTask from "../../modals/CreateNewTask";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";
import UpdatePhase from "../../modals/UpdatePhase";
import ClonePhase from "../../modals/ClonePhase";

function CellPhaseSection({
  colIndex,
  taskIndex,
  cellInfo,
  userRole,
  statusOfPhase,
  teams,
  onSave,
  onSaveTask,
  handleDelete,
  onClonePhase,
}) {
  const phaseInfo = cellInfo ?? {};

  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloneOpen, setIsCloneOpen] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [phaseId, setPhaseId] = useState(phaseInfo?.id);

  const handleOnDrag = (e) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ taskIndex, prevColIndex: colIndex })
    );
  };

  const renderAddTask = () => {
    if (userRole !== "Admin" && userRole !== "Manager") {
      return (
        <MDBRow style={{ marginTop: 5 }}>
          <MDBCol
            size="3"
            sm="8"
            className=" truncate max-w-[250px]"
            title={`${phaseInfo?.assigned ?? "TBD"}`}
          >
            <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
            {phaseInfo?.assigned ?? "TBD"}
          </MDBCol>
        </MDBRow>
      );
    }

    return (
      <MDBRow style={{ marginTop: 5 }}>
        <MDBCol
          size="3"
          sm="8"
          className=" truncate max-w-[250px]"
          title={`${phaseInfo?.assigned ?? "TBD"}`}
        >
          <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
          {phaseInfo?.assigned ?? "TBD"}
        </MDBCol>

        <MDBCol size="3" sm="2" title={"Add new task"}>
          <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => setOpenTaskModal(true)}
          >
            <MDBIcon fas icon="plus" />
          </div>
        </MDBCol>
        <MDBCol size="3" sm="2" title="Delete this phase">
          <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => {
              setIsConfirmDelete(true);
              setPhaseId(phaseInfo?.id);
            }}
          >
            <MDBIcon fas icon="trash-alt" color="warning" />
          </div>
        </MDBCol>
      </MDBRow>
    );
  };

  const renderCloneCtrl = () => {
    if (userRole !== "Admin" && userRole !== "Manager")
      return (
        <>
          <MDBCol size="4">
            <div
              style={{ cursor: "pointer", alignItems: "flex-end" }}
              onClick={() => setIsModalOpen(true)}
              title={`Update information of ${phaseInfo?.name}`}
            >
              <MDBIcon fas icon="eye" />
            </div>
          </MDBCol>
        </>
      );

    return (
      <>
        <MDBCol size="2">
          <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => setIsModalOpen(true)}
            title={`Update information of ${phaseInfo?.name}`}
          >
            <MDBIcon fas icon="user-edit" />
          </div>
        </MDBCol>
        <MDBCol size="2">
          <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => setIsCloneOpen(true)}
            title={`Clone ${phaseInfo?.name}`}
          >
            <MDBIcon fas icon="clone" />
          </div>
        </MDBCol>
      </>
    );
  };

  const renderMainItem = () => {
    return (
      <div
        draggable
        onDragStart={handleOnDrag}
        className=" w-[280px] first:my-5 rounded-lg  bg-white  dark:bg-[#2b2c37] shadow-[#364e7e1a] py-6 px-3 shadow-lg hover:text-[#635fc7] dark:text-white dark:hover:text-[#635fc7] cursor-pointer "
      >
        <p className=" font-bold tracking-wide " title={phaseInfo?.description}>
          <MDBRow>
            <MDBCol size="8" className=" truncate max-w-[300px]">
              {phaseInfo?.name}
            </MDBCol>
            {renderCloneCtrl()}
          </MDBRow>
        </p>
        <div style={{ border: "1px solid gray" }} />
        <CurrentLogTimeStatus
          statusName={phaseInfo?.statusName}
          estimateHours={phaseInfo?.estimateHours}
          normalHours={phaseInfo?.normalHours ?? 0}
          overTime={phaseInfo?.overTimeHours ?? 0}
          startDate={phaseInfo?.startDate}
          endDate={phaseInfo?.endDate}
        />
        {renderAddTask()}
      </div>
    );
  };

  return (
    <div>
      {renderMainItem()}
      {isConfirmDelete && phaseId && (
        <ConfirmDeleteDialog
          openModal={true}
          titleDelete={`Delete phase ${phaseInfo?.name}`}
          onCloseModal={() => setIsConfirmDelete(false)}
          onDelete={() => {
            handleDelete(phaseId);
            setIsConfirmDelete(false);
          }}
        />
      )}
      {isModalOpen && (
        <UpdatePhase
          isModalOpen={isModalOpen}
          userRole={userRole}
          phaseInfo={phaseInfo}
          onSave={onSave}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {isCloneOpen && (
        <ClonePhase
          isCloneOpen={isCloneOpen}
          setIsCloneOpen={setIsCloneOpen}
          phaseInfo={phaseInfo}
          statuses={statusOfPhase ?? []}
          teams={teams ?? []}
          userRole={userRole}
          onSave={onClonePhase}
        />
      )}
      {openTaskModal && (
        <CreateNewTask
          phaseId={phaseInfo?.id}
          phaseName={phaseInfo?.name}
          statuses={statusOfPhase ?? []}
          teams={(teams ?? []).filter((item) => item.isActive)}
          userRole={userRole}
          onCloseModal={() => setOpenTaskModal(false)}
          onSave={(data) => onSaveTask(data)}
        />
      )}
    </div>
  );
}

export default CellPhaseSection;
