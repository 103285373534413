import {
  MDBBtn,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";

function Avatar() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserInfo(infoObj);
  }, []);

  const handleLogOut = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("userInfo");
    navigate(`/`);
  };

  return (
    <>
      <MDBDropdown light style={{ backgroundColor: '#e3f2fd' }}>
        <MDBDropdownToggle split className="nav-link" role="button">
          <MDBBtn>
            <MDBIcon fas icon="user-circle" style={{ marginRight: 15 }} /> Hi,{" "}
            {userInfo?.displayName}
          </MDBBtn>
        </MDBDropdownToggle>

        <MDBDropdownMenu>
          <MDBDropdownItem
            onClick={() => navigate(`/users/${userInfo?.id}`)}
            className="d-flex p-2"
          >
            Welcome, {userInfo?.role}
          </MDBDropdownItem>

          <MDBDropdownItem divider />
          <MDBDropdownItem
            onClick={() => navigate(`/users/settings`)}
            className="d-flex p-2"
          >
            <MDBIcon fas icon="cogs" style={{ marginRight: 15 }} />
            Settings
          </MDBDropdownItem>

          <MDBDropdownItem
            onClick={() => navigate(`/users/notifications`)}
            className="d-flex p-2"
          >
            <MDBIcon fas icon="bell" style={{ marginRight: 15 }} />
            Notifications
          </MDBDropdownItem>

          {/* <MDBDropdownItem
            onClick={() => navigate(`/users/language`)}
            className="d-flex p-2"
          >
            <MDBIcon fas icon="language" style={{ marginRight: 15 }} />
            Language
          </MDBDropdownItem> */}
          <MDBDropdownItem divider />
          <MDBDropdownItem
            onClick={() => handleLogOut()}
            className="d-flex p-2"
          >
            Log Out
            <MDBIcon fas icon="sign-out-alt" style={{ marginLeft: 15 }} />
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
    </>
  );
}

export default Avatar;
