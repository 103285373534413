import { orderBy } from "lodash";
import {
  MDBAccordionItem,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";

function PhaseMenuItem({
  phase,
  currentId,
  taskInfoId,
  userRole,
  onSelectPhase,
  onSelectTask,
  onShowCheckListOfPhase,
}) {

  const renderTaskMenus = (taskMenus) => {
    if (taskMenus?.length < 1) return <></>;

    let items = orderBy(taskMenus ?? [], "priorityNumber");
    let hasIdx = items?.length > 1;
    return (
      <MDBListGroup style={{ marginLeft: 15, padding: 5 }} light>
        {items.map((item, idx) => {
          return (
            <MDBListGroupItem
              title={`Task : ${item?.description}`}
              color={taskInfoId === item?.id ? "success" : "transparent"}
              className=" truncate max-w-[120px]"
              onClick={() => onSelectTask(item)}
            >
              {hasIdx && `${idx + 1} : `} {item?.name}
            </MDBListGroupItem>
          );
        })}
      </MDBListGroup>
    );
  };

  const renderControlShowCheckList = () => {
    if (userRole === "Admin" || userRole === "Manager") {
      return (
        <MDBIcon
          fas
          icon="chalkboard-teacher"
          style={{ marginLeft: 10, marginTop: -5 }}
        />
      );
    }

    return (
      <MDBIcon
        fas
        icon="list-ol"
        style={{ cursor: "pointer", marginLeft: 10, marginTop: -5 }}
        title="Show check list of team"
        onClick={() => onShowCheckListOfPhase(phase)}
      />
    );
  };

  const renderBody = () => {
    if ((phase?.taskMenus ?? []).length < 1) return <div onClick={() => onSelectPhase(phase)}
      style={{ cursor: "pointer", padding: 15 }}
      className=" truncate max-w-[125px]">
      {phase?.name} {currentId === phase.id && renderControlShowCheckList()}
    </div>

    return <MDBAccordionItem collapseId={phase?.id} headerTitle={<>
      {phase?.name}
      <MDBIcon fas icon="eye"
        onClick={() => onSelectPhase(phase)}
        style={{ cursor: "pointer", padding: 15 }} />
      {currentId === phase.id && renderControlShowCheckList()}
    </>} title={phase?.name}>

      {renderTaskMenus(phase?.taskMenus ?? [])}
    </MDBAccordionItem>
  }

  return renderBody();

}
export default PhaseMenuItem;
