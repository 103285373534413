import React, { useEffect, useState } from "react";
import PhaseInfo from "./PhaseInfo";
import ColumnSection from "./ColumnSection";

function MainBoard({
  columns,
  projectId,
  projects,
  statuses,
  currentPhaseId,
  statusOfPhase,
  userRole,
  isProject,
  teams,
  staffsOfPhase,
  onSelectProject,
  onSelectPhase,
  changeStatus,
  onSavePhase,
  handleAddSubtask,
  handleUpdateSubtask,
  onSaveProject,
  onSaveTask,
  handleDelete,
  onUpdateTask,
  handleDeleteTask,
  onClonePhase,
  onSelectTask,
  statusOfTask,
  taskInfoId,
  taskInfo,
  handleDeleteSubTask,
  reloadPhaseInfo,
  onShowCheckListOfPhase,
  handleLogWork
}) {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);

  const handleDragTask = (colIndex, prevColIndex, taskIndex) => {
    let curStatus = columns[colIndex].status;
    let curTaskId = columns[prevColIndex].entities[taskIndex]?.id;
    changeStatus(curTaskId, curStatus);
  };

  return (
    <div
      style={{
        marginTop: 25,
        height: "93vh",
        backgroundImage: "linear-gradient(white, gray)",
      }}
      className={
        windowSize[0] >= 768 && isSideBarOpen
          ? " bg-[#f4f7fd] h-screen flex dark:bg-[#20212c] overflow-x-scroll g" +
            "ap-6 ml-[261px]"
          : "bg-[#f4f7fd] h-screen flex dark:bg-[#20212c] overflow-x-scroll ga" +
            "p-6 "
      }
    >
      {windowSize[0] >= 768 && (
        <PhaseInfo
          setIsBoardModalOpen={setIsBoardModalOpen}
          isBoardModalOpen={isBoardModalOpen}
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
          userRole={userRole}
          projects={projects}
          statuses={statuses}
          projectId={projectId}
          currentPhaseId={currentPhaseId}
          taskInfoId={taskInfoId}
          onSelectProject={onSelectProject}
          onSelectPhase={onSelectPhase}
          onSaveProject={onSaveProject}
          onSelectTask={onSelectTask}
          onShowCheckListOfPhase={onShowCheckListOfPhase}
        />
      )}

      {/* Columns Section */}
      {(columns ?? []).map((col, index) => (
        <ColumnSection
          key={index}
          colIndex={index}
          statusOfPhase={statusOfPhase}
          isProject={isProject}
          teams={teams}
          userRole={userRole}
          staffsOfPhase={staffsOfPhase}
          columnInfo={col}
          dragTask={(colIndex, prevColIndex, taskIndex) =>
            handleDragTask(colIndex, prevColIndex, taskIndex)
          }
          onSavePhase={onSavePhase}
          handleAddSubtask={handleAddSubtask}
          handleUpdateSubtask={handleUpdateSubtask}
          onSaveTask={onSaveTask}
          handleDelete={handleDelete}
          onUpdateTask={onUpdateTask}
          handleDeleteTask={handleDeleteTask}
          onClonePhase={onClonePhase}
          taskInfoId={taskInfoId}
          taskInfo={taskInfo}
          statusOfTask={statusOfTask}
          handleDeleteSubTask={handleDeleteSubTask}
          reloadPhaseInfo={reloadPhaseInfo}
          currentPhaseId={currentPhaseId}
          handleLogWork={handleLogWork}
        />
      ))}
    </div>
  );
}

export default MainBoard;
