import {
  MDBBadge,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { useEffect } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import CurrentLogTimeStatus from "../pages/Projects/CurrentLogTimeStatus";
import { AgGridReact } from "ag-grid-react";
import { orderBy } from "lodash";
import { dateFormatDMY } from "../utils/utilities";

function ViewTeamMembers({
  taskName,
  teamId,
  teamName,
  taskDetailInfo,
  onCloseModal,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    { field: "assigned", headerName: "Staff" },
    { field: "name", filter: "agSetColumnFilter" },
    { field: "statusName", width: 65, headerName: "Status" },
    { field: "estimateHours", width: 65, headerName: "Estimate" },
    { field: "normalHours", width: 65, headerName: "Working" },
    { field: "overTimeHours", width: 65, headerName: "OT" },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
  ];

  const renderSubByUsers = (subTasks) => {
    if (subTasks?.length < 1) return <>Don't have any sub tasks</>;
    let items = (subTasks ?? []).filter(
      (it) =>
        (it?.estimateHours ?? 0) +
        (it?.normalHours ?? 0) +
        (it?.overTimeHours ?? 0) >
        0
    );

    return (
      <>
        <div className="ag-theme-quartz" style={{ height: 275 }}>
          <AgGridReact
            rowData={orderBy(items ?? [], "priorityNumber")}
            columnDefs={colDefs}
          />
        </div>
      </>
    );
  };

  const renderBody = () => {
    let subTasks = (taskDetailInfo?.subTasks ?? []).filter(
      (t) => t.teamId === teamId
    );

    return (
      <>
        <CurrentLogTimeStatus
          statusName={taskDetailInfo?.statusName}
          estimateHours={taskDetailInfo?.estimateHours}
          normalHours={taskDetailInfo?.normalHours ?? 0}
          overTime={taskDetailInfo?.overTimeHours ?? 0}
          startDate={taskDetailInfo?.startDate}
          endDate={taskDetailInfo?.endDate}
        />
        <div style={{ border: "1px solid gray" }} />
        {renderSubByUsers(subTasks)}
      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className=" truncate max-w-[300px]">
              {taskName}
            </MDBModalTitle>
            <MDBBadge
              pill
              className="mx-2"
              color="info"
              light
              style={{ alignItems: "flex-end", marginLeft: 25 }}
            >
              {teamName}
            </MDBBadge>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>

          <MDBModalBody>{renderBody()}</MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default ViewTeamMembers;
