import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { toCommas } from "../../utils/utilities";

function UpdatePrice({ setIsOpenModal, userPrice, setUserPrice, updatePrice }) {
  return (
    <MDBModal open={true} onClose={() => setIsOpenModal(false)} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Update Price</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setIsOpenModal(false)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBInput
              className="mb-3"
              type="number"
              id="price"
              label="Price"
              defaultValue={userPrice}
              style={{ padding: 5 }}
              onChange={(evt) => setUserPrice(evt.target.value)}
            />
            <MDBCol size="12" sm="4">
              {toCommas(userPrice)}
            </MDBCol>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setIsOpenModal(false)}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => updatePrice()}>Save </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default UpdatePrice;
